import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  INotification,
} from "../common/interfaces";
import { CommonService } from "../services/CommonService";

interface NotificationState {
  notifications: INotification[]
}

interface NotificationActions {
  sendNotification: (notification: INotification) => void;
  getNotifications: (userId: string) => void;
  reset: () => void;
}

const initialState: NotificationState = {
  notifications: [],
}

export const useNotificationStore = create<NotificationState & NotificationActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        sendNotification: (notification: INotification) => {
          CommonService.requestAuthorize(notification)
        },
        getNotifications: (userId: string) => {
          CommonService.getNotificationByUserId(userId).then((response) => {
            if(response.success){
              set({notifications: response.object})
            }
          })
        },
        reset: () => {
          set(initialState);
        }
      }),
      {
        name: "notification-store",
      }
    ),

  )
)
