import { ICashBox, ICustomer, IResponse, IWarehouse} from "../common/interfaces";
import { request } from "./AjaxService";

const baseApi = process.env.REACT_APP_API_URL;
const baseRoute = 'customer'
const baseServiceRoute = `${baseApi}${baseRoute}`;
export const CustomerService = {
  getCustomers: async (name?: string, rfc= ''): Promise<Array<ICustomer>> => {
    const response = await fetch(`${baseServiceRoute}?nombre=${name}&rfc=${rfc}`);
    return await response.json();
  
  },
  createCustomer: async (customer: Partial<ICustomer>): Promise<IResponse<ICustomer>> => {
    const response = await fetch(`${baseServiceRoute}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(customer)
    });
    return await response.json();
  
  },
  getCustomerByPhone: async (phone: string): Promise<IResponse<ICustomer>> => {
    const response = await fetch(`${baseServiceRoute}/${phone}`);
    return await response.json();
  },
  updateCustomerByPhone: async (customer: Partial<ICustomer>): Promise<IResponse<ICustomer>> => {
    const response = await fetch(`${baseServiceRoute}/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(customer)
    });
    return await response.json();
  },
  updateCustomerById: async (id: number,customer: Partial<ICustomer>): Promise<IResponse<ICustomer>> => await request.put(`${baseRoute}/pos/${id}/update`, customer),
  getCustomerById: async (id: number): Promise<IResponse<ICustomer>> => await request.get(`${baseRoute}/id/${id}`),
};
