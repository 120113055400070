import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "./App.css";
import Login from "./pages/Login";
import Home from "./pages/Home";
import PrivateRoute from "./auth/PrivateRoute";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Clear } from "./pages/Clear";
import Pusher from "pusher-js"
import { PusherProvider } from "./context/pusherContext";
// Pusher.logToConsole = true;
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const pusher = new Pusher(process.env.REACT_APP_PUSHER_CONFIG_KEY ?? '', {
  cluster: process.env.REACT_APP_PUSHER_CONFIG_CLUSTER ?? '',
  forceTLS: true,
})

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <PusherProvider pusher={pusher}>
        <CssBaseline />
        <Router>
          <Switch >
            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/clear" component={Clear} />
            <Route exact path="/login" component={Login} />
          </Switch>
        </Router>
      </PusherProvider>
    </ThemeProvider>
  );
}

export default App;