import {
  Snackbar,
  Alert,
  AlertColor,
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "../auth/useLocalStorage";
import { CashBoxService } from "../services/CashBoxService";
import { CommonService } from "../services/CommonService";
import { Details, ITablajeria } from "../common/interfaces";
import { tab } from "@testing-library/user-event/dist/tab";
import { InventoryService } from "../services/InventoryService";
import { set } from "lodash";

interface CloseBoxProps {
  handleCancel: () => void;
}

export const Tablajeria = (props: CloseBoxProps) => {
  const { handleCancel } = props;
  const [user, setUser] = useLocalStorage("user", null);
  const handleCloseBox = (event: any) => {
    if(process === ''){
      CommonService.displayAlert('Debes seleccionar un proceso de Tablajeria')
      return
    
    }

    const formInfo = new FormData(event.currentTarget);
    event.preventDefault();
    const quantity = parseFloat(formInfo.get('quantity')?.toString() as string) ?? 0
    
    if(warehouse === ''){
      CommonService.displayAlert('Debes seleccionar un almacen origen')
      return
    
    }
    
    let aux = []
    let valItemsInprocess= 0
    for(let key in productsTablajeo){
      aux.push({
        base_product_id: tablajeriaInfo?.product_id
        , quantity: productsTablajeo[key]
        , result_product_id: key
      })
      valItemsInprocess += productsTablajeo[key]
    }
    
    if(valItemsInprocess > quantity){
      CommonService.displayAlert('La cantidad de items en proceso es mayor a la cantidad de items a tablajear')
      return
    
    }
    
    const data = {
      board_process_id: tablajeriaInfo
      , comments: 'Created BY POS'
      , origin_product_id: tablajeriaInfo?.product_id
      , origin_quantity: formInfo.get('quantity')?.toString()
      , targetProduct: aux
      , warehouse: warehouseList.find((item: any) => item.id.toString() === formInfo.get('warehouse')?.toString())
      , user_id: user.id
      , cash_box_id: user.cbox.id
      , is_pos: 1
    }

    CommonService.addTablajeriaProcess(data).then((res) => {
      if (res.success) {
        CommonService.displayAlert(res.message)
        handleCancel();
      }else{
        CommonService.displayAlert(res.message)
      }
    })
    
    
  };

  const [process, setProcess] = React.useState("");
  const [warehouse, setWarehouses] = React.useState(`${user?.warehouse_id}` ?? "");
  const [options, setOptions] = React.useState<Record<string, any>>(
    [] as unknown as Record<string, string>
  );
  const [tablajeriaList, setTablajeriaList] = React.useState<any[]>([]);
  const [tablajeriaInfo, setTablajeriaInfo] = React.useState<
    ITablajeria | undefined
  >(undefined);
  const [warehouseList, setWarehouseList] = React.useState<any[]>([]);
  const handleChange = (event: SelectChangeEvent) => {
    setProcess(event.target.value as string);
  };
  const [productsTablajeo, setProductsTablajeo] = useState<Record<string, any>>({})

  useEffect(() => {
    CommonService.getTablajeriaProcess().then((res) => {
      if (res.length > 0) {
        setOptions(
          res.map((item: ITablajeria) => ({
            name: item.name,
            value: item.id,
          }))
        );
        setTablajeriaList(res);
      }
    });
    InventoryService.getWarehouses().then((res) => {
      if (res.length > 0) {
        setWarehouseList(res);
      }
    });

  }, []);
  useEffect(() => {
    console.log({ process });
    if (process === "") return;
    const index = tablajeriaList.findIndex(
      (item: any) => item.id === parseInt(process)
    );

    setTablajeriaInfo(tablajeriaList[index]);
  }, [process]);

  return (
    <Box component="form" onSubmit={handleCloseBox} noValidate sx={{ mt: 1 }}>
      <Typography variant="h6" component="h2" gutterBottom>
        Proceso de Tablajeria
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel id="demo-simple-select-label">
              Proceso de tablajeria
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={process}
              name="process"
              label="Proceso de Tablajeria"
              onChange={handleChange}
            >
              {options.map((item: any, index: number) => (
                <MenuItem value={item.value} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel id="demo-simple-select-label">
              Almacen Origen
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="warehouse"
              required
              value={warehouse}
              onChange={(e) => setWarehouses(e.target.value)}
              label="warehouse"
            >
              {warehouseList.length > 0 &&
                warehouseList
                .filter((warehouse) => warehouse.id === user.warehouse_id)
                .map((warehouse) => (
                  <MenuItem value={warehouse.id} key={warehouse.id}>
                    {warehouse.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          
        </Grid>
        <Grid item xs={6}>
        {tablajeriaInfo && (
            <TextField
              margin="normal"
              disabled
              fullWidth
              id="cards"
              label="Producto Base"
              name="card"
              value={tablajeriaInfo.product.name}
              autoFocus
            />
          )}
          {tablajeriaInfo && (
            <TextField
              margin="normal"
              type="number"
              required
              fullWidth
              id="cards"
              label="Cantidad A tablajear"
              name="quantity"
              autoFocus
            />
          )}
        </Grid>
      </Grid>
      <hr />
      <table className="table table-striped" style={{ marginTop: 20, width:"100%" }}>
        {
          tablajeriaInfo?.details.map((item: Details, index:number) => (
            <tr>
              <td><Typography>{item.result_product.name}</Typography></td>
              <td><TextField
              margin="normal"
              type="number"
              required
              fullWidth
              id="cards"
              value={productsTablajeo[item.result_product.id.toString()]}
              onChange={(e) => {
                setProductsTablajeo({
                  ...productsTablajeo,
                  [item.result_product.id.toString()]: e.target.value
                })
              }}
              label="Cantidad Producida"
              name="quantity"
              autoFocus /></td>
            </tr>
          ))
        }
      </table>

      <Button
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => handleCancel()}
      >
        Cancelar
      </Button>
      <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, ml: 3 }}>
        Guardar y Salir
      </Button>
    </Box>
  );
};
