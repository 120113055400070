import React from "react";
import { useNotificationStore } from "../../context/notificationStore";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  styled,
  tableCellClasses,
  Button,
  ButtonGroup,
} from "@mui/material";
import { CommonService } from "../../services/CommonService";
import { useLocalStorage } from "../../auth/useLocalStorage";
import moment from "moment";
import { useModalStore } from "../../context/modalStore";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const Notifications: React.FC = () => {
  const notification = useNotificationStore();
  const modalManager = useModalStore()
  const [user] = useLocalStorage("user", null);
  const viewNotification = (id: number, message: string, action: string) => {
    CommonService.displayAlert(message, "info").then((result) => {
      if (result.isConfirmed) {
        CommonService.readNotification(`${id}`).then((e) =>{
          notification.getNotifications(user.user.id)
        });
      }
    });
  };

  return (
    <>
    <div className="pull-right">
      <ButtonGroup variant="outlined" aria-label="Loading button group">
        <Button onClick={() => CommonService.readAllNotification(user.user.id).then(() => {
          CommonService.toast("Notificaciones marcadas como leídas", "success")
          notification.getNotifications(user.user.id)
          modalManager.closeModal()
        })}>Marcar todo Como leído</Button>
      </ButtonGroup>

    </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>De</StyledTableCell>
              <StyledTableCell>Mensaje</StyledTableCell>
              <StyledTableCell>Enviado</StyledTableCell>
              <StyledTableCell>Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notification.notifications.map((row) => (
              <StyledTableRow
                key={row.id}
                onClick={() =>
                  viewNotification(row.id, row.message, row.action)
                }
              >
                <StyledTableCell component="th" scope="row">
                  {row.fromUser.nombre}
                </StyledTableCell>
                <StyledTableCell>{row.message}</StyledTableCell>
                <StyledTableCell>
                  {moment(row.created_at).format("DD/MM/YYYY HH:mm:ss")}
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
