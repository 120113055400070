import { Box, TextField, Button } from "@mui/material";
import React from "react";
import { useModalStore } from "../../context/modalStore";
import { ICustomer } from "../../common/interfaces";
import { CustomerService } from "../../services/CustomerService";
import { CommonService } from "../../services/CommonService";
import { usePosStore } from "../../context/posStore";

export const AddCustomer: React.FC = () => {
  const setCustomer = usePosStore((state) => state.setCustomer);
  const modalManager = useModalStore()
  const handleSubmitCreateCustomer = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const info: Partial<ICustomer> = {
      nombre: data.get("nombre")?.toString() ?? "",
      email: data.get("email")?.toString() ?? "",
      telefono: data.get("phone")?.toString() ?? "",
      direccion: data.get("direccion")?.toString() ?? "",
      rfc: "",
      usoCFDI: "",
      regimenFiscal: "",
      codigoPostal: "",
    };
    if (info.nombre === "") {
      CommonService.toast("El nombre es obligatorio", "error");
      return;
    }
    CustomerService.createCustomer(info).then((res) => {
      setCustomer(res.object as ICustomer);
      modalManager.closeModal();
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmitCreateCustomer}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="user"
        label="Nombre"
        name="nombre"
        autoFocus
      />
      <TextField
        margin="normal"
        fullWidth
        name="direccion"
        label="Direccion"
        type="text"
        id="direccion"
      />
      <TextField
        margin="normal"
        fullWidth
        name="phone"
        label="Telefono"
        type="phone"
        id="phone"
      />
      <TextField
        margin="normal"
        fullWidth
        name="email"
        label="Email"
        type="email"
        id="email"
      />
      <Button
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => modalManager.closeModal()}
      >
        Cancelar
      </Button>
      <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, ml: 3 }}>
        Guardar y Seleccionar
      </Button>
    </Box>
  );
};
