import {
  FormControl,
  OutlinedInput,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useMemo } from "react";
import { usePosStore } from "../../context/posStore";
import { ComponentModal, ICustomer } from "../../common/interfaces";
import lodash from "lodash";
import { CustomerService } from "../../services/CustomerService";
import { useModalStore } from "../../context/modalStore";

export const Customer: React.FC = () => {
  const setCustomer = usePosStore((state) => state.setCustomer);
  const listCustomers = usePosStore((state) => state.listCustomers);
  const setListCustomers = usePosStore((state) => state.setListCustomers);
  const modalActions = useModalStore((state) => state);
  const [searchCustomer, setSearchCustomer] = React.useState("");
  const doCallbackWithDebounce = useMemo(() => {
    const callback = () => {};
    return lodash.debounce(callback, 1500);
  }, []);
  const getCustomers = (name?: string) => {
    CustomerService.getCustomers(name).then((res: ICustomer[]) => {
      setListCustomers(res);
    });
  };
  useEffect(() => {
    getCustomers(searchCustomer);
  }, [searchCustomer])
  useEffect(() => {
    getCustomers()
  }, [])
  return (
    <FormControl style={{ width: "100%" }} variant="outlined">
      <OutlinedInput
        id="outlined-adornment-weight-search"
        placeholder="Cliente"
        onChange={(event) => {
          doCallbackWithDebounce();
          setSearchCustomer(event.target.value);
        }}
        value={searchCustomer}
        // style={{ width: "100%" }}
        startAdornment={<SearchIcon />}
        endAdornment={
          <IconButton onClick={() => modalActions.setComponent(ComponentModal.AddCustomer)}>
            <PersonAddIcon />
          </IconButton>
        }
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
          "aria-label": "weight",
        }}
      />

      <div>
        <List>
          {listCustomers.map((customer) => (
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    setCustomer(customer);
                    modalActions.closeModal()
                  }}
                >
                  <AddIcon />
                </IconButton>
              }
              key={customer.id}
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${customer.nombre} (${customer.id})`}
                secondary={customer.rfc}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </FormControl>
  );
};
