import React from "react";
import { Redirect, Route } from "react-router-dom";
import { createContext } from "react";
import { useLocalStorage } from "./useLocalStorage";

interface IUser {
  token?: string
  userId?: string
  userName?: string
  login?: any
  logout?: any
}

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}


const baseUser: IUser = {}
const AuthContext = createContext(baseUser);

export default function PrivateRoute({
  exact = false,
  path,
  component
}: IProps) {
  const [user,] = useLocalStorage("user", baseUser);
  const isLoggedIn = user?.token ? true : false
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (isLoggedIn) {
          return <Route {...props} component={component} />;
        } else if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: process.env.REACT_APP_PREFIX + "login",
                state: { from: props.location }
              }}
            />
          );
        } else {
          return null;
        }
      }}
    />
  );
}
