import React from "react";
import { useLocalStorage } from "../auth/useLocalStorage";

export const BottomInfo: React.FC = () => {
  const [user] = useLocalStorage("user", null);
  return (
    <div>
      <span style={{ float: "right", fontSize: "12px", textAlign: "right" }}>
        CORTE: {user?.cbox?.id}
      </span>
    </div>
  );
};
