import React, { useEffect } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useLocalStorage } from "../auth/useLocalStorage";
import { Paper, Typography, IconButton, Stack, Badge } from "@mui/material";
import { usePosStore } from "../context/posStore";
import { useModalStore } from "../context/modalStore";
import { ComponentModal } from "../common/interfaces";
import NotesIcon from "@mui/icons-material/Notes";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { CommonService } from "../services/CommonService";
import { useNotificationStore } from "../context/notificationStore";
import { usePusher } from "../context/pusherContext";
import { v4 } from "uuid";
export const HeaderCartView: React.FC = () => {
  const modalManager = useModalStore();
  const order = usePosStore((state) => state.order);
  const products = usePosStore((state) => state.products);
  const [user] = useLocalStorage("user", null);
  const customer = usePosStore((state) => state.customer);
  const notification = useNotificationStore()
  const pusher = usePusher()
  useEffect(() => {
    notification.getNotifications(user.user.id)
  }, [])
  return (
    <Paper elevation={3} style={{ padding: "5px" }}>
      <Typography variant="h6" gutterBottom>
        Le atiende: <strong>{user.user.nombre}</strong>
      </Typography>
      <Typography variant="h6" gutterBottom>
        Cliente: <strong>{customer.nombre}</strong>
      </Typography>
      <IconButton
        aria-label="notes"
        onClick={() => {
          modalManager.openModal(ComponentModal.OrderNotes, {});
        }}
        style={{
          float: "right",
          top: "-60px",
        }}
      >
        <NotesIcon />
      </IconButton>
      <IconButton
        aria-label="scheduleOrders"
        onClick={() => {
          modalManager.openModal(ComponentModal.ScheduleOrders, {});
        }}
        style={{
          float: "right",
          top: "-60px",
        }}
      >
        <CalendarMonthIcon />
      </IconButton>

      <IconButton
        aria-label="notification"
        onClick={() => {
          modalManager.openModal(ComponentModal.Notification, {});
        }}
        style={{
          float: "right",
          top: "-60px",
        }}
      >
        <Badge color="secondary" badgeContent={notification.notifications.length}>
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <IconButton
        aria-label="delete"
        onClick={() => {
          if (products.length === 0) {
            modalManager.openModal(ComponentModal.Utilidades, {
              utilOption: "movements",
              onlyOrders: true,
            });
            return;
          }
          modalManager.openModal(ComponentModal.OrderOptions, {
            order,
          });
        }}
        style={{
          float: "right",
          top: "-60px",
        }}
      >
        <AddShoppingCartIcon />
      </IconButton>
    </Paper>
  );
};
