import {
  Snackbar,
  Alert,
  AlertColor,
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Chip,
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "../auth/useLocalStorage";
import { CashBoxService } from "../services/CashBoxService";
import { CommonService } from "../services/CommonService";
import { Details, ITablajeria } from "../common/interfaces";
import { tab } from "@testing-library/user-event/dist/tab";
import { InventoryService } from "../services/InventoryService";
import { set } from "lodash";

interface CloseBoxProps {
  handleCancel: () => void;
}

export const Productions = (props: CloseBoxProps) => {
  const { handleCancel } = props;
  const [user, setUser] = useLocalStorage("user", null);
  const handleCloseBox = (event: any) => {
    const formInfo = new FormData(event.currentTarget);
    event.preventDefault();

    if (warehouse === "") {
      CommonService.displayAlert("Debes seleccionar un almacen origen");
      return;
    }
    if(tablajeriaInfo && tablajeriaInfo.length === 0){
      CommonService.displayAlert('Debe seleccionar un proceso a producir')
      return
    }
    const data=[]
    if(tablajeriaInfo){
      for (let index = 0; index < tablajeriaInfo.length; index++) {
        const element = tablajeriaInfo[index];
        data.push({
          name: element.name,
          id: element.id,
          inventory: { cost : element.inventory.cost},
          origin_quantity: productsTablajeo[element.product.id],
          product_id: element.product.id,
        })
      }
    }
    const completeDate = {
      user_id: user.id,
      is_pos: 1,
      cash_box_id: user.cbox.id,
      warehouse: warehouseList.find((item: any) => item.id.toString() === formInfo.get('warehouse')?.toString()),
      productions: data
    }


    CommonService.addProductionsProcess(completeDate).then((res) => {
      if (res.success) {
        CommonService.displayAlert(res.message)
        handleCancel();
      }else{
        CommonService.displayAlert(res.message)
      }
    })
  };

  const [process, setProcess] = React.useState<any[]>([]);
  const [warehouse, setWarehouses] = React.useState(
    `${user?.warehouse_id}` ?? ""
  );
  const [options, setOptions] = React.useState<Record<string, any>>(
    [] as unknown as Record<string, string>
  );
  const [tablajeriaList, setTablajeriaList] = React.useState<any[]>([]);
  const [tablajeriaInfo, setTablajeriaInfo] = React.useState<any[] | undefined>(
    undefined
  );
  const [warehouseList, setWarehouseList] = React.useState<any[]>([]);
  const handleChange = (event: SelectChangeEvent<any[]>) => {
    // console.log(event.target.value)
    setProcess(event.target.value as any[]);
  };
  const [productsTablajeo, setProductsTablajeo] = useState<Record<string, any>>(
    {}
  );

  useEffect(() => {
    CommonService.getProductionProcess().then((res) => {
      if (res.length > 0) {
        setOptions(
          res.map((item: any) => ({
            name: item.name,
            value: item.id,
          }))
        );
        setTablajeriaList(res);
      }
    });
    InventoryService.getWarehouses().then((res) => {
      if (res.length > 0) {
        setWarehouseList(res);
      }
    });
  }, []);
  useEffect(() => {
    console.log({ process });
    if (process.length === 0) return;
    let aux: any[] = [];
    process.forEach((item: any) => {
      const index = tablajeriaList.findIndex(
        (i: any) => i.id === parseInt(item.value)
      );

      if (index > -1) {
        aux.push(tablajeriaList[index]);
      }
    });

    setTablajeriaInfo([...aux]);
  }, [process]);

  return (
    <Box component="form" onSubmit={handleCloseBox} noValidate sx={{ mt: 1 }}>
      <Typography variant="h6" component="h2" gutterBottom>
        Proceso de Produccion
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel id="demo-simple-select-label">
              Proceso de Produccion
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              multiple
              value={process}
              name="process"
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={'selected_' + value.value} label={value.name} />
                  ))}
                </Box>
              )}
              label="Proceso de Tablajeria"
              onChange={handleChange}
            >
              {options.map((item: any, index: number) => (
                <MenuItem value={item} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
        </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel id="demo-simple-select-label">
              Almacen
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="warehouse"
              required
              value={warehouse}
              onChange={(e) => setWarehouses(e.target.value)}
              label="warehouse"
            >
              {warehouseList.length > 0 &&
                warehouseList
                .filter((warehouse) => warehouse.id === user.warehouse_id)
                .map((warehouse) => (
                  <MenuItem value={warehouse.id} key={warehouse.id}>
                    {warehouse.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <hr />
      <table
        className="table table-striped"
        style={{ marginTop: 20, width: "100%" }}
      >
        {
          tablajeriaInfo && tablajeriaInfo.map((item: any, index:number) => (
            <tr>
              <td><Typography>{item.product.name}</Typography></td>
              <td><TextField
              margin="normal"
              type="number"
              required
              fullWidth
              id="cards"
              value={productsTablajeo[item.product.id.toString()]}
              onChange={(e) => {
                setProductsTablajeo({
                  ...productsTablajeo,
                  [item.product.id.toString()]: e.target.value
                })
              }}
              label="Cantidad a Producir"
              name="quantity"
              autoFocus /></td>
            </tr>
          ))
        }
      </table>

      <Button
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => handleCancel()}
      >
        Cancelar
      </Button>
      <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, ml: 3 }}>
        Guardar y Salir
      </Button>
    </Box>
  );
};
