import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { CommonService } from "../services/CommonService";
import { min } from "lodash";

interface MapProps {
  setAddress: (address: string) => void;
  setShipping: (shipping: number) => void;
  setLocation: (location: any) => void;
  orderTotal: number;
  address: string;
  defaultPoint: IMarker;
}

const containerStyle = {
  width: "100%",
  height: "500px",
};



interface IMarker {
  lat: number;
  lng: number;
}
const pricesDistances = {};
export const Map: React.FC<MapProps> = ({
  setAddress,
  setLocation,
  orderTotal,
  setShipping,
  address,
  defaultPoint: defaultCenter
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY ?? "",
  });
  console.log({defaultCenter})
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(16);
  const [map, setMap] = React.useState(null);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [distance, setDistance] = useState(0);
  const [time, setTime] = useState("");
  const [markers, setMarkers] = useState<IMarker[]>([defaultCenter]);
  // <Marker position={center} />
  const geocoder = new google.maps.Geocoder();
  const [minDistance, setMinDistance] = useState(1000)
  const [minTotal, setMinTotal] = useState(500)
  const [basePrice, setBasePrice] = useState(30)
  const [priceByMinute, setPriceByMinute] = useState(2)
  const [priceByKm, setPriceByKm] = useState(5)


  const onLoad = React.useCallback(function callback(map: any) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);
  const getDistance = (des: any) => {
    const origin = new google.maps.LatLng(defaultCenter.lat, defaultCenter.lng);
    const destiny = new google.maps.LatLng(des.lat, des.lng);
    const service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destiny],
        travelMode: google.maps.TravelMode.DRIVING,
      },
      callback
    );

    function callback(response: any, status: any) {
      console.log({ response, status });
      if (status === "OK") {
        if(inputRef.current){
          inputRef.current.value = response.destinationAddresses[0]
        }
        const distance = response.rows[0].elements[0].distance.value;
        console.log({ distance });
        // 1 km a la redonda: 30 pesos fijos.  
        // 1 km a la redonda: + de $500 consumo: envío gratis.
        
        // más de 1 km: $30 base + 5 pesos km recorrido + 2 pesos por min.

        // let price = 
        if (distance <= minDistance && orderTotal < minTotal) {
          console.log({minDistance, minTotal})
          setShippingPrice(basePrice);
          setShipping(basePrice);
        }
        if (distance > minDistance ) {
          const n = Number(distance) - 1000;
          const ss = (n / 1000).toFixed(0)
          const minutes = response.rows[0].elements[0].duration.value / 60;
          const extra = (orderTotal > minTotal) ? basePrice : 0;
          const price = basePrice + (priceByKm * Number(ss)) + (priceByMinute * minutes) - extra;
          setShippingPrice(price);
          setShipping(price);
        }
        if(distance <= minDistance && orderTotal > minTotal){
          setShippingPrice(0);
          setShipping(0);
        }
        setDistance(distance);
        setTime(response.rows[0].elements[0].duration.text);
      }
    }
  };
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  const inputRef = useRef<HTMLInputElement>(null);
  const setMarker = (place: google.maps.places.PlaceResult) => {
    if (place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      console.log({ place });
      const result = { lat, lng };
      setMarkers([result]);
      setCenter(result);
    }
  };
  const setMarkerByMark = (place: IMarker) => {
    // if(place.geometry && place.geometry.location){
    const lat = place.lat;
    const lng = place.lng;
    const result = { lat, lng };
    setMarkers([result]);
    setCenter(result);
    setZoom(15);
    // }
  };
  const geocode = (request: google.maps.GeocoderRequest): void => {
    // clear();

    geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
        const resultPoint = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        setMarkers([resultPoint]);
        setCenter(resultPoint);
        setZoom(16);
        getDistance(resultPoint);
        setLocation({
          ...resultPoint,
          results,
        });

      })
      .catch((e) => {
        console.log("Geocode was not successful for the following reason: " + e);
      });
  };
  
  const geocode2 = (request: google.maps.GeocoderRequest): void => {
    // clear();

    geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
        const resultPoint = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        // setMarkers([resultPoint]);
        setCenter(resultPoint);
        setZoom(16);
        getDistance(resultPoint);
        setLocation({
          ...resultPoint,
          results,
        });
      })
      .catch((e) => {
        console.log("Geocode was not successful for the following reason: " + e);
      });
  };
  const onDragMarker = (coord: any) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMarkers([{ lat, lng }]);
    geocode({
      location: { lat, lng },
    })
    console.log(lat);
    
};
  useEffect(() => {
    const options = {
      componentRestrictions: { country: "mx" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
    };

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current as HTMLInputElement,
      options
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log({ place });
      setMarker(place);
      // console.log(inputRef.current?.value)
      setAddress(inputRef.current?.value as string);
      const address: {
        [key: string]: any;
      } = {};
      if (place.address_components) {
        for (let index = 0; index < place.address_components.length; index++) {
          const element = place.address_components[index];
          address[element.types[0]] = element.long_name;
        }
      }
      getDistance({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      });
      setLocation({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        address,
      });
    });
    CommonService.getPosConfig().then((res) => {
      console.log({res})
      if(res.success && res.object){
        for(const item of res.object){
          if(item.nombre === 'shipping_base_price'){
            setBasePrice(Number(item.valor))
          }
          if(item.nombre === 'shipping_distance_price_free'){
            setMinDistance(Number(item.valor))
          }
          if(item.nombre === 'shipping_amount_price_free'){
            setMinTotal(Number(item.valor))
          }
          if(item.nombre === 'shipping_time_price'){
            setPriceByMinute(Number(item.valor))
          }
          if(item.nombre === 'shipping_distance_price'){
            setPriceByKm(Number(item.valor))
          }
        }
      }
    })
  }, []);
  useEffect(() => {
    if (address !== "" && inputRef.current) {
      inputRef.current.value = address;
      // geocode({ address: address })
    };
  }, [address]);
  return (
    <>
      <input
        ref={inputRef}
        id="pac-input"
        type="text"
        placeholder="Busque su dirección"
        className="input-custom"
        autoComplete="off"
      />

      {isLoaded ? (
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {markers.map((marker, i) => (
              <Marker position={marker} key={i} draggable={true}
              onDragEnd={onDragMarker}
              />
            ))}
          </GoogleMap>
        </>
      ) : (
        <></>
      )}
      <h5>Distancia aproximada: {(distance / 1000).toFixed(2)} km</h5>
      {time && <h5>Tiempo aproximado: {time}</h5>}
      <h3 style={{ textAlign: "center" }}>
        Costo de Envio: $ {shippingPrice.toFixed(2)}
      </h3>
      <h3 style={{ textAlign: "center" }}>
        Total de Pedido: $ {(shippingPrice + orderTotal).toFixed(2)}
      </h3>
    </>
  );
};
