import React from "react";
import { usePosStore } from "../context/posStore";
import { TableContainer, Paper, Table, TableBody, TableRow, TableCell } from "@mui/material";

export const TotalsRow: React.FC = () => {
  const order = usePosStore((state) => state.order)
  const { subtotal, iva, ieps, descuento, total } = order;
  const orderProducts = usePosStore((state) => state.products)
  return (
    <div>
      {orderProducts.length > 0 && (
        <TableContainer
          component={Paper}
          style={{
            marginTop: "10px",
            float: "right",
            backgroundColor: "#333",
            color: "#fff",
          }}
        >
          <Table aria-label="simple table">
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                className="table-row-total"
              >
                <TableCell component="th" scope="row" style={{ width: "10%" }}>
                  Subtotal
                </TableCell>
                <TableCell align="right">{subtotal}</TableCell>
                <TableCell component="th" scope="row" style={{ width: "10%" }}>
                  IVA
                </TableCell>
                <TableCell align="right">{iva}</TableCell>
                <TableCell component="th" scope="row" style={{ width: "10%" }}>
                  IEPS
                </TableCell>
                <TableCell align="right">{ieps}</TableCell>
                <TableCell component="th" scope="row" style={{ width: "10%" }}>
                  Descuento
                </TableCell>
                <TableCell align="right">{descuento}</TableCell>
                <TableCell component="th" scope="row" style={{ width: "10%" }}>
                  Total
                </TableCell>
                <TableCell align="right">{total}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
