import React, { useEffect, useState } from 'react';
import { usePosStore } from '../../context/posStore';
import { Button, ModalManager, TextField } from '@mui/material';
import { useModalStore } from '../../context/modalStore';
export const OrderNotes: React.FC = () => {
  const order = usePosStore((state) => state.order)
  const modalManager = useModalStore()
  const setOrder = usePosStore((state) => state.setOrder)
  const [value, setValue] = useState(order.notes_order)
  useEffect(() => {
    setOrder({...order, notes_order: value})
  }, [value])
  return (
    <>
      <TextField
          id="notes-for-order"
          label="Notas"
          multiline
          rows={4}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
        />
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Button
          onClick={() => modalManager.closeModal()}
          variant="contained"
          color="info"
          style={{ marginRight: "20px" }}
        >
          Aceptar
        </Button>
      </div>
    </>
  )
}