import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { usePosStore } from "../context/posStore";
import { IProduct, IProductOption } from "../common/interfaces";
import lodash, { debounce } from "lodash";
import { productsService } from "../services/productsService";
import { CommonService } from "../services/CommonService";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Loader } from "./loader";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css"
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const ProductSideBar: React.FC = () => {
  const searchBoxRef = React.useRef<HTMLInputElement>(null);
  const listProducts = usePosStore((state) => state.listProducts)
  const setListProducts = usePosStore((state) => state.setListProducts)
  const products = usePosStore((state) => state.products)
  const setProducts = usePosStore((state) => state.setProducts)
  const reset = usePosStore((state) => state.reset)
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  let selectedOptions: Record<string, any> = {}

  const MIN_QTY = 0.05;

  const handleEnterSearch = (evt: any) => {
    if (evt.key === "Enter") {
      setLoading(true)
      if (/^\d|^\d\*\d+(\.\d{1,5})*?/.test(search)) {
        let [qty, code] = search.split('*');
        if(!qty) qty = '1';
        if(!code) {
          code = qty
          qty = '1'
        };
        
        productsService.getProductByCode(code).then((res) => {
          if (res) {
            const nQty = res.is_bulk === '1' ? parseInt(qty) : Number(qty);

            addToShoppingCart({...res, quantity: Number(nQty) ?? 1});
            setSearch("");
            debounceLoadData('');
            setLoading(false)
            return;
          }
          CommonService.toast("Producto no encontrado", "error");
          setSearch('')
          setLoading(false)
        });
      }
      setLoading(false)
    }
  };
  const addToShoppingCart = (product: IProduct) => {
    const index = products.findIndex(
      (item: IProduct) => item.id === product.id
    );

    if (index === -1) {
      if (product.options && product.options.length > 0) {
        selectedOptions = {}
        MySwal.fire({
          background:"#fff",
          title: (<span className="text-center" style={{color:"#333"}}>Seleccione las opciones</span>),
          html: (
            <div>
              {product.options.map((option: IProductOption) => {
                const subOptions: Array<string> = JSON.parse(
                  option.sub_options
                );
                return (
                  <div key={option.id}>
                    <FormControl fullWidth style={{ marginTop: 20 }}>
                      <InputLabel id="demo-simple-select-label">
                        {option.name}
                      </InputLabel>

                      <NativeSelect
                        name="process"
                        defaultValue=""
                        onChange={(e) => {
                          return selectedOptions[option.name] = e.target.value as string;
                        }}
                      >
                        <option value=""></option>
                        {subOptions.map((item: any, index: number) => (
                          <option value={item} key={index} style={{zIndex:9999999999}}>
                            {item}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </div>
                );
              })}
            </div>
          ),
          showCancelButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: 'Agregar',
        }).then((result) => {
          if (result.isConfirmed) {
            let optionSelectedString = ''
            console.log({selectedOptions})
            for (const option in selectedOptions) {
              const optionSelected = selectedOptions[option]
              optionSelectedString += `${option}: ${optionSelected},`
            }
            optionSelectedString = optionSelectedString.slice(0, -1);
            
            setProducts([...products, { ...product, quantity: product.quantity ?? 1, selectedOptions: optionSelectedString.toUpperCase() }]);
            searchBoxRef.current?.focus()
          }
        })
        setSearch('')
        return;
      }
      if (product.is_bulk === '0'){
        MySwal.fire({
          title: "Cantidad",
          showCancelButton: true,
          input: 'text',
          allowEscapeKey: false,
          allowOutsideClick: false,
          inputValue: "",
          inputAttributes: {
            min: "0",
          },
          preConfirm(inputValue) {
            if(Number.isNaN(Number(inputValue))){
              Swal.showValidationMessage('Solo valores numéricos permitidos')   
              return false
            }

            if(Number(inputValue) < MIN_QTY){
              Swal.showValidationMessage('La cantidad mínima son 50 gramos (0.05 kg).');   
              return false;
            }

            return inputValue
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setProducts([...products, { ...product, quantity: Number(result.value) ?? 1 }]);
            searchBoxRef.current?.focus()
          }
        })
        setSearch('')
        return;
      }
      setSearch('')
      setProducts([...products, { ...product, quantity: product.quantity ?? 1 }]);
    } else {
      setSearch('')
      const qty = products[index]?.quantity ?? 1;
      products[index].quantity = Number(qty) + Number(product.quantity ?? 1);
      setProducts([...products]);
    }
    searchBoxRef.current?.focus()
  };
  const getProducts = (filter: string) => {
    productsService.getProducts(filter).then((res) => {
      setListProducts(res.data);
      setLoading(false);
    });
  }
  const debounceLoadData = useCallback(debounce(getProducts, 1000), []);
  const doCallbackWithDebounce = useMemo(() => {
    const callback = () => {};
    return lodash.debounce(callback, 1500);
  }, []);
  useEffect(() => {
    setLoading(true);
    getProducts('');
  }, [])
  return (
    <FormControl style={{ width: "100%" }} variant="outlined">
      <OutlinedInput
        id="outlined-adornment-weight"
        placeholder="Busque su producto"
        onChange={(event) => {
          doCallbackWithDebounce();
          setSearch(event.target.value);
          if (!/^\d$|^\d\*$|^\d\*\d+(\.\d{0,5})*$/.test(event.target.value)) {
            debounceLoadData(event.target.value);
          }
        }}
        onKeyUp={handleEnterSearch}
        value={search}
        endAdornment={
          <InputAdornment position="end">
            <img
              className="img-fluid"
              src={`${process.env.PUBLIC_URL}/img/barcode01.png`}
              style={{ width: "50px" }}
              alt="logo"
            />
          </InputAdornment>
        }
        aria-describedby="outlined-weight-helper-text"
        inputRef={searchBoxRef}
        inputProps={{
          "aria-label": "weight",
          autoComplete: "off",
        }}
      /> 
      {/* <button onClick={reset}>Reset</button> */}
      <div style={{ overflow: "auto", height: "750px" }}>
        <List>
          <Loader display={loading} />
          {!loading && listProducts.map((product: any) => (
            <ListItem
              onClick={() => addToShoppingCart(product)}
              key={product.id}
              className="list-item-product"
            >
              <ListItemAvatar>
                <Avatar>
                  <ShoppingBasketIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${product.name}`}
                secondary={"$ " + (product.price_1 * (1 + Number(product.iva?.porcentaje ?? 0))).toFixed(2)}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </FormControl>
  );
};
