import { rest } from "lodash";
import { IProduct, IResponse, IResultPagination } from "../common/interfaces";
import { request } from "./AjaxService";

const baseApi = process.env.REACT_APP_API_URL;
export const productsService = {
  getProducts: async (search: string): Promise<IResultPagination<IProduct>> => {
    const params: Record<string, any> = {
      search,
      perPage: 9,
      pos: 1,
    };

    const qs = "?" + new URLSearchParams(params).toString();
    const res = await fetch(`${baseApi}products${qs}`);
    return await res.json();
  },
  getProductsByWarehouse: async (search: string, warehouse_id: string): Promise<IResultPagination<IProduct>> => {
    const params: Record<string, any> = {
      search,
      perPage: 9,
      pos: 0,
      warehouse_id
    };
    return request.get('products', params)
  },
  getProductByCode: async (code: string): Promise<IProduct | undefined> => {
    const res = await fetch(`${baseApi}product/${code}`);
    const prod: IResponse<IProduct> = await res.json();
    return prod.object
  }
};
