import React from "react";

export interface ExchangeRateProps {
  exchangeRate: number;
}

export const ExchangeRate: React.FC<ExchangeRateProps> = ({ exchangeRate }) => (
  <span className="exchangeRate" style={{ float: "right", fontWeight: "bold" }}>
    1 USD: $ {exchangeRate} MXN
  </span>
);
