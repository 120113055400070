import React, { useEffect, useState } from "react";
import { CommonService } from "../../services/CommonService";
import { useLocalStorage } from "../../auth/useLocalStorage";
import { TableContainer, Paper, Button, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { mappingOperations, EMapStatusSale, ISale, IDetails, ISaleDetail } from "../../common/interfaces";
import VisibilityIcon from '@mui/icons-material/Visibility';
export const ScheduleOrders: React.FC = () => {
  const [user, ] = useLocalStorage("user", null);
  const [rows, setRows] = useState([])
  const [view, setView] = useState('list')
  const [sale, setSale] = useState<ISale>()
  const [selectedSale, setSelectedSale] = useState(0)
  const warehouseId = user?.warehouse_id
  useEffect(() => {
    CommonService.getScheduleOrders(warehouseId).then((response: any) => {
      console.log("Schedule Orders", response)
      if(response.success){
        setRows(response.object)
      }
    })
  }, [])
  useEffect(() => {
    if(view === 'detail'){
      if(selectedSale > 0){
        CommonService.getSaleInfo(selectedSale).then((response: any) => {
          console.log("Ticket Order Information", response)
          if(response.success){
            setSale(response.object)
          }
        })
      }
    }
  }, [view])
  const viewItem = (item: any) => {
    setSelectedSale(item.order.id)
    setView('detail')
  }
  return (
    <div>
      {
        view === 'list' && (
          <TableContainer component={Paper}>
            <table
              style={{
                width: "100%",
                fontSize: "14px",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                    ID
                  </th>
                  <th
                    style={{ width: "20%", borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                    Cliente
                  </th>
                  <th
                    style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                    Monto
                  </th>
                  <th
                    style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                    Fecha
                  </th>
                  <th
                    style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                    Hora
                  </th>
                  <th
                    style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                    align="center"
                  >
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row: any) => (
                  <tr key={row.id}>
                    <td align="center">{row.order.id ? `S-${row.order.id}` : ''}</td>
                    <td align="center">{row.order.cliente.nombre}</td>
                    <td align="center">{row.order.total}</td>
                    <td align="center">{moment(row.schedule_data).format("DD/MM/YYYY")}</td>
                    <td align="center">{moment(row.schedule_data).format("hh:mm a")}</td>
                    <td align="center">
                      <IconButton
                        aria-label="delete"
                        onClick={() => viewItem(row)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
                {/* {rows.map((row: any) => (
                  <tr key={row.id}>
                    <td align="center">{row.id}</td>
                    <td align="center">{row.client_name}</td>
                    <td align="center">{row.total_amount}</td>
                    <td align="center">{moment(row.date).format("DD/MM/YYYY")}</td>
                    <td align="center">{moment(row.date).format("HH:mm")}</td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          </TableContainer>
        )
      }
      {
        view === 'detail' && (
          <div>
            <Typography style={{ fontSize: "18px" }}>Pedido: { sale?.id }</Typography>
            <Typography style={{ fontSize: "18px" }}>Cliente: { sale?.cliente?.nombre }</Typography>
            <Typography style={{ fontSize: "18px" }}>Monto: $ { sale?.total }</Typography>
            <Typography style={{ fontSize: "18px" }}>Fecha y Hora de Agendación: { moment(sale?.schedule).format("DD/MM/YYYY HH:mm a") }</Typography>
            <Typography style={{ fontSize: "18px" }}>Fecha y Hora de Creacion: { moment(sale?.created_at).format("DD/MM/YYYY  HH:mm a") }</Typography>
            <br />
            <br />
            <Typography style={{ fontSize: "18px" }}>Detalles de Productos:</Typography>
            <TableContainer component={Paper}>
            <table style={{
                width: "100%",
                fontSize: "14px",
                borderCollapse: "collapse",
              }}>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>Iva</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                { sale?.detalles.map((detail: ISaleDetail) => (
                  <tr key={detail.id}>
                    <td>{ detail.producto.name }</td>
                    <td>{ detail.cantidad }</td>
                    <td>{ detail.precio }</td>
                    <td>{ detail.iva }</td>
                    <td>{ detail.total }</td>

                  </tr>
                )) }
                </tbody>
            </table>
            </TableContainer>
            <br />
            <br />
            <Button onClick={() => setView('list')}>Volver</Button>
          </div>
        )
      }
    </div>
  );
}