import * as React from "react";
import { useEffect, useState } from "react";
import { CommonService } from "../services/CommonService";
import { IProduct, IResultPagination, ISupplier, ProductPO } from "../common/interfaces";
import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

export interface CreatePOProps {
  supplierId?: number;
  addProduct: (product: ProductPO) => void;
}

export default function PoProduct(props: CreatePOProps) {
  const [search, setSearch] = useState<string>("");
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [resultsProducts, setResultProducts] =
    useState<IResultPagination<IProduct>>();
  const headers = [
    {
      id: 1,
      // align: 'left',
      minWidth: "50%",
      name: "Producto",
      prop: "nombre",
    },
    {
      id: 2,
      // align: 'right',
      minWidth: "50%",
      name: "Precio",
      prop: "cost",
    },
  ];
  useEffect(() => {
    fetchProducts();
  }, []);
  useEffect(() => {
    fetchProducts();
  }, [page, search, perPage]);
  const fetchProducts = () => {
    if (props.supplierId) {
      CommonService.getProductsBySupplier(props.supplierId, {
        page,
        perPage,
        search,
      }).then((value: IResultPagination<IProduct>) => setResultProducts(value));
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(+event.target.value);
    setPage(1);
  };
  return (
    <div>
      <div>
        <Input
          fullWidth
          type="standard"
          placeholder="Busque su producto"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <TableContainer sx={{ height: 330 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headers.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {resultsProducts &&
                resultsProducts.data.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id} onClick={() => props.addProduct({
                      product: row,
                      price: Number(row.cost),
                      quantity: 1,
                      iva: 0,
                      iva_percent: 0,
                      subtotal: 0,
                      total: 0
                    })}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.cost}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={Number(resultsProducts?.total)}
          rowsPerPage={Number(resultsProducts?.per_page)}
          page={resultsProducts?.current_page ?? 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
