import React, { useEffect } from "react";
import { UtilButtons } from "../utilsButtons";
import { useModalStore } from "../../context/modalStore";

export const Utilidades: React.FC = () => {
  const modalManager = useModalStore()
  const [defaultOpen, setDefaultOpen] = React.useState<string>(modalManager?.params?.utilOption);
  useEffect(() => {
    modalManager.setTitle(modalManager?.params?.title ?? "Utilidades")
  }, [])
  return (
    <UtilButtons
      defaultOpen={defaultOpen}
      setDefault={(val: string) => setDefaultOpen(val)}
      closeModal={() => modalManager.closeModal()}
      setModalTitle={modalManager.setTitle}
    />
  )
}