import * as React from "react";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LogoutIcon from "@mui/icons-material/Logout";
import { usePosStore } from "../context/posStore";
import { ComponentModal } from "../common/interfaces";
import { useModalStore } from "../context/modalStore";
import { CommonService } from "../services/CommonService";

export interface MenuProps {}

export const MainMenu: React.FC<MenuProps> = () => {
  const modalManager = useModalStore((state) => state);
  const products = usePosStore((state) => state.products);
  const customer = usePosStore((state) => state.customer);
  const order = usePosStore((state) => state.order);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        className="img-fluid"
        src={`${process.env.PUBLIC_URL}/img/logo1.png`}
        style={{ width: "100px" }}
        alt="logo"
      />
      <Stack direction="row" spacing={2} marginTop={5}>
        <Paper>
          <MenuList>
            <MenuItem
              className="menuIcons"
              onClick={() => modalManager.openModal(ComponentModal.Customer)}
            >
              <div>
                <PersonIcon />
                <br />
                <div>Clientes</div>
              </div>
            </MenuItem>
            <MenuItem
              className="menuIcons"
              onClick={() => modalManager.openModal(ComponentModal.Utilidades)}
            >
              <div>
                <SettingsIcon />
                <br />
                <div>Utilidades</div>
              </div>
            </MenuItem>
            <MenuItem
              className="menuIcons"
              onClick={() => modalManager.openModal(ComponentModal.Cancel)}
            >
              <div>
                <DeleteForeverIcon />
                <br />
                <div>Eliminar</div>
              </div>
            </MenuItem>
            <MenuItem
              className="menuIcons"
              onClick={() => {
                if (products.length === 0) {
                  CommonService.toast("No hay productos en la orden", "error");
                  return;
                }
                modalManager.openModal(ComponentModal.Finish, {
                  order,
                  products,
                  customer,
                  type: 'order',
                });
              }}
            >
              <div>
                <DoneIcon />
                <br />
                <div>Finalizar (F2)</div>
              </div>
            </MenuItem>
            <MenuItem
              className="menuIcons"
              onClick={() => modalManager.openModal(ComponentModal.LogOut, {
                utilOption: "closeBox",
                title: "Cerrar Sesión",
              })}
            >
              <div>
                <LogoutIcon />
                <br />
                <div>Cerrar Sesión</div>
              </div>
            </MenuItem>
          </MenuList>
        </Paper>
      </Stack>
    </div>
  );
};
