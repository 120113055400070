import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ComponentModal, TitleComponents } from "../common/interfaces";

interface ModalAction {
  closeModal: () => void;
  openModal: (by?: ComponentModal, params?: Record<string, any>) => void;
  setTitle: (by: TitleComponents | string) => void;
  setComponent: (componentName?: ComponentModal, params?: Record<string, any>) => void;
}

interface ModalProps {
  title: string | TitleComponents,
  open: boolean,
  component?: string,
  params: Record<string, any>,
}

export interface IModal {
  title: TitleComponents | string;
  open: boolean;
  component: ComponentModal | "";
  params?: Record<string, any>;
}

const initialState: IModal = {
  title: TitleComponents.Customer,
  open: false,
  component: "",
  params: {},
};

export const useModalStore = create<IModal & ModalAction>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        closeModal: () => set({ ...get(), open: false }),
        openModal: (componentName?: ComponentModal, params?: Record<string, any>) => {
          if (componentName) {
            set({
              ...get(),
              component: componentName,
              open: true,
              params: params ?? {},
            });
            return;
          }
          set({ ...get(), open: true });
        },
        setTitle: (title: TitleComponents | string) => set({ ...get(), title: title }),
        setComponent: (componentName?: ComponentModal, params?: Record<string, any>) => set({ ...get(), component: componentName, params: {...get().params, ...params} }),
      }),
      {
        name: "pos-modal-status",
      }
    )
  )
);
