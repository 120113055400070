import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  styled,
  debounce,
  Typography,
  Button,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { productsService } from "../services/productsService";
import lodash from "lodash";
import { IProduct } from "../common/interfaces";
const Demo = styled("div")(({ theme }) => ({}));

export const VerifyPrice = () => {
  const [search, setSearch] = React.useState("");
  const [products, setProducts] = React.useState([] as IProduct[]);
  const doCallbackWithDebounce = useMemo(() => {
    const callback = () => {};
    return lodash.debounce(callback, 500);
  }, []);
  const debounceLoadData = useCallback(debounce(getProducts, 1000), []);
  function getProducts(filter: string) {
    productsService.getProducts(filter).then((res) => {
      setProducts(res.data);
    });
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if(event.target.value.length < 4) return;
      productsService.getProductByCode(event.target.value).then((res) => {
        if (res) {
          console.log(res)
          setProducts([res]);
        }else{
          setProducts([])
        }
        setSearch("");
      })
    }
  }

  // useEffect(() => {

  //   if (/^\d/.test(search)) {
  //     console.log('here');
  //     productsService.getProductByCode(search).then((res) => {
  //       if (res) {
  //         console.log(res)
  //         setProducts([res]);
  //         setSearch("");
  //       }
  //     });
  //     return;
  //   }
  //   debounceLoadData(search);
  // }, [search]);

  return (
    <div>
      <FormControl style={{ width: "100%" }} variant="outlined">
        <OutlinedInput
          id="outlined-adornment-weight-search2"
          placeholder="Busque su producto"
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          onKeyDown={handleKeyDown}
          value={search}
          // style={{ width: "100%" }}
          endAdornment={
            <InputAdornment position="end">
              <img
                className="img-fluid"
                src={`${process.env.PUBLIC_URL}/img/barcode01.png`}
                style={{ width: "50px" }}
                alt="logo"
              />
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
<div style={{ display: "flex", flexDirection: "column", textAlign:"center", paddingBottom:"30px"}}>
        {products.length > 0 ? (
          <>
            <Typography style={{ fontSize: "25px" }}>
              {products[0].name}
            </Typography >
            <Typography style={{ fontSize: "35px" }}>
              PRECIO: $ { (products[0].price_1 * (1 + Number(products[0]?.iva?.porcentaje ?? 0))).toFixed(2)} MXN
              </Typography>
          </>
        ) : (
          <Typography style={{ fontSize: "35px" }}>NO HAY RESULTADOS</Typography>
        )}
        </div>
      </FormControl>
      <div style={{ display: "flex", justifyContent: "center" }}>
        
      </div>
    </div>
  );
};
