import { Typography, Button, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ICustomer } from "../../common/interfaces";
import { CommonService } from "../../services/CommonService";
import { usePusher } from "../../context/pusherContext";
import { v4 } from "uuid";
import { usePosStore } from "../../context/posStore";
import { useLocalStorage } from "../../auth/useLocalStorage";
import { useModalStore } from "../../context/modalStore";

export const Cancel: React.FC = () => {
  const pusher = usePusher();
  const products = usePosStore((state) => state.products);
  const clearOrder = usePosStore((state) => state.clearOrder);
  const modalManager = useModalStore()
  const [user,] = useLocalStorage('user', null)
  const [idAuth, setIdAuth] = useState(0);
  const order = usePosStore((state) => state.order);
  const cancelRequest = () => {
    const channel = pusher.subscribe("general-channel");
    const idCancel = v4();
    CommonService.requestAuthorize({
      message: `Se solicita Cancelar Venta`,
      action: {
        type: "cancel-item",
        item: { ...products.map((e) => ({ ...e, image: null })) },
        id: idCancel,
        content: `Se solicita Orden: <br />Products: <br />${products.map(prod => `${prod.name} - ${prod.quantity}`).join('<br />')} <br/> Total Orden ${order.total}`,
      },
      status: "pending",
      from: user.id,
      to: 1,
    }).then((res) => {
      if (res.success) {
        setIdAuth(res.object.id);
        channel.bind(
          "push-notification",
          (event: { object: { to?: any; status: string; id: string } }) => {
            if (
              event.object.to === user.id &&
              event.object.status === "authorized" &&
              event.object.id === idCancel
            ) {
              clearOrder();
              CommonService.toast("Cancelacion Autorizada", "success")
              modalManager.closeModal()
            }
            if (
              event.object.to === user.id &&
              event.object.status === "declined" &&
              event.object.id === idCancel
            ) {
              CommonService.toast("Cancelacion No Autorizada", "error")
              modalManager.closeModal()
            }

            channel.unbind("push-notification");
            channel.unsubscribe();
          }
        );
      }
    });
  };
  const handleCancel = () => {
    if (idAuth > 0) {
      CommonService.cancelRequestAuthorize({
        message: ``, // => $req->message,
        id: idAuth,
        status: "pending",
        from: user.id,
        to: 1,
      }).then(() => console.log("Cancelando AUTH"));
      setIdAuth(0);
    }
    modalManager.closeModal()
  };

  useEffect(() => {
    cancelRequest()
  }, [])
  return (
    <>
      <Typography style={{ marginBottom: "20px" }}>
        Se envió solicitud para Cancelar la Orden
      </Typography>
      <LinearProgress />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Button
          onClick={handleCancel}
          variant="contained"
          color="error"
          style={{ marginRight: "20px" }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
