import * as React from "react";
import { useEffect, useState } from "react";
import { CommonService } from "../services/CommonService";
import {
  IProduct,
  IResultPagination,
  ISupplier,
  ProductPO,
} from "../common/interfaces";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import PoProduct from "./PoProduct";
import { useLocalStorage } from "../auth/useLocalStorage";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css"
import withReactContent from "sweetalert2-react-content";
import DeleteIcon from "@mui/icons-material/Delete";
import { usePosStore } from "../context/posStore";
const MySwal = withReactContent(Swal);

export interface CreatePOProps {
  supplierId?: number;
  handleCancel: () => void;
}

export default function CreatePO(props: CreatePOProps) {
  const [user, setUser] = useLocalStorage("user", null);
  const purchaseOrder = usePosStore((state) => state.purchaseOrder);
  const setPurchaseOrder = usePosStore((state) => state.setPurchaseOrder)
  const clearPurchaseOrder = usePosStore((state) => state.clearPurchaseOrder)
  const [supplier, setSupplier] = useState<ISupplier>();
  const [openModal, setOpenModal] = useState(false);
  const [products, setProducts] = useState<Array<ProductPO>>([]);
  const [total, setTotal] = useState(0);
  const [ivaTotal, setIvaTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState("");
  useEffect(() => {
    console.log('checando PO ACtual', purchaseOrder)
    if (props.supplierId) {
      if(purchaseOrder[props.supplierId] && purchaseOrder[props.supplierId].products?.length > 0){
        console.log('here', purchaseOrder[props.supplierId].products)
        setProducts(purchaseOrder[props.supplierId].products)
        setSupplier(purchaseOrder[props.supplierId].supplier)
      }else{
        CommonService.getSupplierById(props.supplierId).then((value: ISupplier) => {
          setSupplier(value)
          setPurchaseOrder(`${props.supplierId}`, {
            ...purchaseOrder[`${props.supplierId}`]
            , supplier: value
            , products: []
          })
        });
        CommonService.createPOBySupplier(props.supplierId, user.warehouse_id).then((value) => {
          const {success, object: products} = value
          if(success){
            console.log({products})
            setProducts(products || []);
          }
        })

      }
    }
  }, []);
  useEffect( () => {
    setPurchaseOrder(`${supplier?.id}`, {
      ...purchaseOrder[`${supplier?.id}`],
      products
    })
  }, [products])

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const style = {
    position: "relative",
    maxHeight: "700px",
    overflow: "auto",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#333",
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const addProduct = (product: ProductPO) => {
    console.log({ product });
    setOpenModal(false);
    if (products.find((p) => p.product.id === product.product.id)) {
      return;
    }
    setProducts([...products, product]);
    calculateTotal([...products, product]);
    setPurchaseOrder(`${supplier?.id}`, {
      ...purchaseOrder[`${supplier?.id}`], products: [...products, product]
    })
    setOpenModal(false);
  };

  const calculateTotal = (products: ProductPO[]) => {
    let total = 0;
    let subtotal = 0;
    let ivaTotal = 0;
    let newProducts = [];
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      product.iva_percent = Number(product.iva_percent);
      product.subtotal = Number(product.price) * Number(product.quantity);
      product.iva = Number(product.subtotal) * Number(product.iva_percent);
      product.total = Number(product.subtotal) + Number(product.iva);
      total += Number(product.total);
      subtotal += Number(product.subtotal);
      ivaTotal += Number(product.iva);
      newProducts.push(product);
    }
    setTotal(total);
    setSubTotal(subtotal);
    setIvaTotal(ivaTotal);
    console.log({ newProducts });
    setProducts([...newProducts]);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setIva(event.target.value);
  };
  const createPosPO = async () => {
    if(products.length === 0){
      CommonService.toast("No hay productos agregados", "error")
      return;
    }
    let isCredit = 0;
    const validatePrices = products.every((product) => product.total > 0)
    if(!validatePrices){
      CommonService.toast("El total de cada producto debe ser mayor a 0", "error")
      return;
    }
    // const {success, object} = await CommonService.getAmountCash(user?.cbox.id);
    // console.log({object})
    // return

    const payload = {
      products: products,
      subtotal: subTotal,
      iva_total: ivaTotal,
      total: total,
      iva,
      supplier_id: supplier?.id,
      user: user?.id,
      cashBox: user?.cbox.id,
      warehouses: user?.warehouse_id,
      credit: Number(supplier?.dias) > 0 ? 1 : 0,
    };
    CommonService.createPO(payload).then((res) => {
      if(res.success){
        CommonService.toast("Orden de compra creada con exito", "success")
        setOpenModal(false);
        setProducts([]);
        setIva('0');
        setTotal(0);
        setSubTotal(0);
        setIvaTotal(0);
        props.handleCancel();
        clearPurchaseOrder(`${supplier?.id}`);
        CommonService.printTicketPO(res.object.id).then((res: any) => console.log(res))
        return;
      }
      CommonService.toast("Error al crear la orden de compra", "error")
    })
  
  }

  const removeItem = async (itemId: number) => {
    const indexToRemove = products.findIndex((item) => item.product.id === itemId);
    if(indexToRemove > -1){
      const aux = products
      aux.splice(indexToRemove, 1);
      if(!aux){
        setProducts([]);
        setProducts([]);
        setPurchaseOrder(`${supplier?.id}`, {
          ...purchaseOrder[`${supplier?.id}`], products: [],
        })
      }else{
        setProducts([...aux]);
        calculateTotal([...aux]);
        setPurchaseOrder(`${supplier?.id}`, {
          ...purchaseOrder[`${supplier?.id}`], products: [...aux]
        })
      }
    }
  }
  return (
    <div>
      <h4>
        Proveedor: {supplier?.nombre}
        <br />Tipo de pago: {Number(supplier?.dias) > 0 ? 'CREDITO' : 'CONTADO'}
        <Button
          style={{ float: "right" }}
          hidden={true}
          onClick={() => setOpenModal(true)}
        >
          Agregar Producto
        </Button>
      </h4>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell align="right">Precio</TableCell>
                <TableCell align="right">IVA</TableCell>
                <TableCell align="right">IVA %</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Subtotal</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((row) => (
                <TableRow
                  key={row.product.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.product.name}
                  </TableCell>
                  <TableCell align="right">{row.price.toFixed(2)}</TableCell>
                  <TableCell align="right">{row.iva.toFixed(2)}</TableCell>
                  <TableCell align="right">
                  <FormControl fullWidth style={{ marginTop: "30px" }}>
                    <InputLabel id="demo-simple-select-label">IVA</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={row.iva_percent.toString()}
                      label="IVA"
                      onChange={(e) => {
                        const newProducts = products.map((p) => {
                          if (p.product.id === row.product.id) {
                            p.iva_percent = Number(e.target.value);
                          }
                          return p;
                        });
                        calculateTotal(newProducts);
                      }}
                    >
                      <MenuItem value={0}>0 %</MenuItem>
                      <MenuItem value={0.08}>8 %</MenuItem>
                      <MenuItem value={0.16}>16 %</MenuItem>
                    </Select>
                  </FormControl>
                  </TableCell>
                  <TableCell align="right">
                    <Input
                      type="number"
                      value={row.quantity}
                      onChange={(e) => {
                        const newProducts = products.map((p) => {
                          if (p.product.id === row.product.id) {
                            p.quantity = Number(e.target.value);
                          }
                          return p;
                        });
                        calculateTotal(newProducts);
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{row.subtotal.toFixed(2)}</TableCell>
                  <TableCell>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeItem(row.product.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4}></TableCell>
                <TableCell>Subtotal</TableCell>
                <TableCell align="right">{subTotal.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}></TableCell>
                <TableCell>IVA</TableCell>
                <TableCell align="right">{ivaTotal.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4}></TableCell>
                <TableCell>Total</TableCell>
                <TableCell align="right">{total.toFixed(2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ marginTop: "30px" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="contained" color="error" onClick={() => props.handleCancel()}>
              Cancelar
            </Button>
            <Button variant="contained" color="success" onClick={() => createPosPO()}>
              Crear Orden de Compra
            </Button>
          </Stack>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "600px" }}>
          <PoProduct supplierId={supplier?.id} addProduct={addProduct} />
        </Box>
      </Modal>
    </div>
  );
}
