import { IWarehouse} from "../common/interfaces";

const baseApi = process.env.REACT_APP_API_URL;
export const InventoryService = {
  getWarehouses: async (): Promise<Array<IWarehouse>> => {
    const response = await fetch(`${baseApi}catalog/warehouses`);
    return await response.json();
    
  }
};
