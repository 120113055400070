import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useEffect, useState } from "react";
import { CommonService } from "../services/CommonService";
import { useLocalStorage } from "../auth/useLocalStorage";
import {
  Concepto,
  IDetails,
  IPurchaseOrder,
  ISupplier,
} from "../common/interfaces";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css";
import withReactContent from "sweetalert2-react-content";
import CreatePO from "./CreatePO";
import PrintIcon from "@mui/icons-material/Print";

interface PurchaseOrdersProps {
  handleCancel: () => void;
}

export const PurchaseOrders = (props: PurchaseOrdersProps) => {
  const { handleCancel } = props;
  const [amount, setAmount] = useState(0);
  const [code, setCode] = useState("");
  const [user, setUser] = useLocalStorage("user", null);
  const [maxAmount, setMaxAmount] = useState(0);
  const [suppliers, setSuppliers] = useState<ISupplier[]>();
  const [poList, setPOList] = useState<any[]>();
  const [pO, setPO] = useState<any>();
  const [selectedPO, setSelectedPO] = useState<IPurchaseOrder>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [step, setStep] = useState("first");
  const [selectedSupplier, setSelectedSupplier] = useState<string[]>([]);
  const [supplier, setSupplier] = useState<ISupplier>();
  const [filter, setFilter] = useState("");
  const [allSuppliers, setAllSuppliers] = useState<ISupplier[]>();
  const [notes, setNotes] = useState("");
  const [serie, setSerie] = useState("");
  const [folio, setFolio] = useState("");
  const [uuid, setUUID] = useState("");
  const [iva, setIva] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState<Concepto[]>([]);
  const style = {
    width: "100%",
    maxWidth: 360,
    border: "1px solid #fff",
    // bgcolor: 'background.paper',
  };
  const MySwal = withReactContent(Swal);
  function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
  }
  const handleStep = () => {
    if (selectedSupplier.length === 0) {
      MySwal.fire({
        icon: "error",
        toast: true,
        position: "top-end",
        title: "Debe seleccionar un proveedor para continuar",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    changeStep("second");
  };
  const changeStep = (step: string) => setStep(step);
  const execute = () => {
    if(invoiceDetails.length > 0){
      if(selectedPO?.details.some(value => value.xmlValue === "")){
        CommonService.displayAlert("Debes asociar todos los productos del CFDI con la orden compra.", 'error')
        return;
      }
    }
    if (selectedPO) {
      let to_credit = "0";
      MySwal.fire({
        title: "¿La compra tiene Factura o nota de Remisión?",
        icon: "question",
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: "Factura",
        denyButtonText: `Nota de remisión`,
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        let is_billable = "1";
        if (result.isConfirmed) {
          is_billable = "1";
        } else if (result.isDenied) {
          is_billable = "0";
        } else {
          return;
        }
        if (selectedPO.is_credit === "0") {
          const cashAmount = await CommonService.getAmountCash(
            user.cbox.id ?? 0
          );
          if (cashAmount.success) {
            if (
              Number(cashAmount.object.amount) <= Number(selectedPO.grand_total)
            ) {
              const res = await MySwal.fire({
                title:
                  "No tienes suficientes fondos en caja para pagar la orden de compra, se pasará a crédito",
                icon: "question",
                showCancelButton: true,
                // showDenyButton: true,
                confirmButtonText: "Confirmar",
                // denyButtonText: `Nota de remisión`,
                cancelButtonText: "Cancelar",
              });
              if (res.isConfirmed) {
                to_credit = "1";
              }
              if (res.dismiss || res.dismiss) {
                return;
              }
            }
          }
        }
        CommonService.createPOReceipt(selectedPO.id, {
          po: selectedPO,
          supplier_id: selectedSupplier[0],
          user_id: user?.id,
          notes,
          serie,
          folio,
          uuid,
          iva,
          is_billable,
          to_credit,
          cashBoxId: user?.cbox.id,
        }).then(async (res) => {
          if (res.success) {
            MySwal.fire({
              icon: "success",
              toast: true,
              position: "top-end",
              title: "Recepcion Creada con exito",
              showConfirmButton: false,
              timer: 1500,
            });
            changeStep("first");
            setSelectedSupplier([]);
            setSelectedPO(undefined);
            setNotes("");
            setSerie("");
            setFolio("");
            setUUID("");
            setIva(0);
            CommonService.printTicketPOR(res.object.id).then((e) =>
              console.log(e)
            );
            handleCancel();
            return;
          }
          MySwal.fire({
            icon: "error",
            toast: true,
            position: "top-end",
            title: "Error al crear la recepción",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      });
    }
  };
  const updateObject = () => {
    if (!selectedPO) return;
    let total = 0;
    let totalTax = 0;
    const newPo = selectedPO;
    newPo.details.map((element) => {
      // element.tax_percent = 0;
      const taxPercent = Number(element?.tax_rate) ?? 0;
      console.log(element.price);
      const base = Number(element.price);
      const quantity = Number(Number(element.quantity).toFixed(2));
      console.log({ taxPercent, base, quantity });
      console.log(base * quantity);
      element.total = (base * quantity - Number(element.discount)).toFixed(2);
      element.tax = (base * taxPercent * quantity).toFixed(2);
      return element;
    });
    total = selectedPO.details.reduce<number>(
      (acc, element) => acc + Number(element.total),
      0
    );
    totalTax = selectedPO.details.reduce<number>(
      (acc, element) => acc + Number(element.tax),
      0
    );

    setSelectedPO({
      ...newPo,
      grand_total: (
        Number(total.toFixed(2)) + Number(totalTax.toFixed(2))
      ).toFixed(2),
      total: total.toFixed(2),
      tax: totalTax.toFixed(2),
    });
  };
  useEffect(() => {
    CommonService.getSuppliers().then((res) => {
      setSuppliers(res);
      setAllSuppliers(res);

      if(res && res.length > 0){
        setSelectedSupplier([String(res[0].id)]);
      }
    });
  }, []);

  useEffect(() => {
    if (step === "second") {
      CommonService.getPO({
        custom: `supplier_id=${selectedSupplier},warehouse_id=${user?.warehouse_id}`,
      }).then((res) => {
        console.log({ res });
        setPOList(
          res.filter((item: IPurchaseOrder) => item.status === "Aprobado")
        );
      });
    }
  }, [step]);
  useEffect(() => {
    if (selectedSupplier[0] !== "") {
      setSupplier(
        suppliers?.find((item) => item.id === Number(selectedSupplier))
      );
    }
  }, [selectedSupplier]);
  useEffect(() => {
    const results = allSuppliers?.filter((e) =>
      e.nombre.toLowerCase().includes(filter.trim().toLowerCase())
    );
    setSuppliers(results);

    if(results && results.length > 0){
      setSelectedSupplier([String(results[0].id)]);
    }
    
  }, [filter]);
  useEffect(() => {
    updateObject();
  }, [iva]);
  function deleteItem(item: IDetails): void {
    const changePO = selectedPO;
    const index: number = Number(
      changePO?.details.findIndex((e) => e.id === item.id)
    );
    console.log({ index, item }, changePO?.details);
    if (index !== -1 && changePO) {
      const arr = changePO?.details;
      arr?.splice(index, 1);
      changePO.details = arr;
      setSelectedPO({ ...changePO });
    }
  }

  useEffect(() => {

    // httpsñ--verificacfdi.facturaelectronica.sat.gob.mx-default.aspx_/id¿8C9B398A'7DB9'4CA6'9B09'E9F15CEA08B2/re¿XEXX010101000/rr¿XEXX010101000/tt¿368.000000/fe¿4sXmbQ¿¿

    // httpsñ--verificacfdi.facturaelectronica.sat.gob.mx-default.aspx_/id¿1FE5B362'63DA'46FF'8E54'1CDBED4D9F91/re¿MOMA681124PE8/rr¿SAGE881223620/tt¿808.000000/fe¿vb1VmA¿¿

    // httpsñ--verificacfdi.facturaelectronica.sat.gob.mx-default.aspx_id¿9ae36137'1fa9'4e10'a14f'ec1deb24db67/re¿SUK931216N38/rr¿SAGE881223620/tt¿0002649.79000000/fe¿¡ZDy7w¿¿

    // httpsñ--verificacfdi.facturaelectronica.sat.gob.mx-default.aspx_/id¿200AEE22'E147'4500'A742'0E49A6C2004C/re¿ARY860520CC6/rr¿SAGE881223620/tt¿0000000269.00/fe¿MEC6ag¿¿

    // httpsñ--verificacfdi.facturaelectronica.sat.gob.mx-default.aspx_/id¿6A9B2F14'5857'4E78'AFDF'C81F1A1F88B9/re¿VNO0111282G5/rr¿SAGE881223620/tt¿3327.48/fe¿s0d7iQ¿¿

    // httpsñ--verificacfdi.facturaelectronica.sat.gob.mx-default.aspx_/id¿D40F445E'E39D'4749'8C02'FB602DB95022/re¿DJB850527F30/rr¿SAGE881223620/tt¿0000001652.510000/fe¿mU5UGQ¿¿
    let auxUuid = uuid;
    if(auxUuid !== ''){
      auxUuid = auxUuid.replace(/\¿/g, '=')
      auxUuid = auxUuid.replace(/'/g, '-')
    }
    console.log({auxUuid})
    const regex = /.+id=([a-zA-z0-9-]+).+/gm;
    const subst = `$1`;
    if(auxUuid.match(regex)){
      const result = auxUuid.replace(regex, subst);
      setUUID(result);
      return
    }

    if (uuid.length === 36) {
      CommonService.displayLoader("Obteniendo Información de CFDI").then(
        (loader: typeof Swal) => {
          CommonService.getXmlByUUID(uuid).then((res) => {
            if (res.success) {
              loader.close();
              const xml = res.object?.xml;
              console.log({ xml });
              if (xml) {
                if (xml.Emisor.Rfc === supplier?.rfc) {
                  // if(xml.Conceptos.Concepto.length !== selectedPO?.details.length){
                  //   CommonService.displayAlert("La orden de Compra no contiene la misma cantidad de items del CFDI, no es posible relacionarlo")
                  //   setSerie("");
                  //   setFolio("");
                  //   setInvoiceDetails([]);
                  //   setUUID("");
                  //   return
                  // }
                  setSerie(xml?.Serie ?? "");
                  setFolio(xml?.Folio ?? "");
                  setInvoiceDetails(xml.Conceptos.Concepto);
                  // validate items 
                  
                  return;
                } else {
                  CommonService.displayAlert(
                    "El CFDI no corresponde al proveedor seleccionado",
                    "error"
                  );
                  setSerie("");
                  setFolio("");
                  setInvoiceDetails([]);
                  setUUID("");
                }
              }
            } else {
              CommonService.displayAlert(res.message, "error");
            }
          }).catch((err) => {
            CommonService.displayAlert("Error al conectar con el sat")
            setSerie("");
            setFolio("");
            setInvoiceDetails([]);
            // setUUID("");
            return
          })
        }
      )
      ;
    }
  }, [uuid]);

  return (
    <>
      {step === "first" && (
        <div style={{ textAlign: "center" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  style={{ width: "100%" }}
                  label="Busque su proveedor"
                  variant="outlined"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Grid>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel shrink htmlFor="select-multiple-native">
                  Proveedores
                </InputLabel>
                <Select
                  inputProps={{
                    id: "select-multiple-native",
                  }}
                  labelId="demo-simple-select-label"
                  // id="demo-simple-select"
                  value={selectedSupplier}
                  name="supplier"
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  multiple
                  native
                  onChange={(e) =>
                    setSelectedSupplier([e.target.value as string])
                  }
                >
                  {suppliers &&
                    suppliers.map((item: ISupplier, index: number) => (
                      <option value={item.id} key={item.id}>
                        {item.id} - {item.nombre}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: "20px" }}
              onClick={() => handleStep()}
            >
              Siguiente
            </Button>
          </div>
        </div>
      )}
      {step === "second" && (
        <div style={{ textAlign: "center" }}>
          <Typography style={{ textAlign: "left" }}>
            Proveedor: {supplier?.nombre}
            {supplier?.permitirPedidosPos === 1 && (
              <Button
                style={{ float: "right" }}
                hidden={true}
                onClick={() => changeStep("createPO")}
              >
                Crear Orden de Compra
              </Button>
            )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <List style={{ width: "100%" }} component="div">
                {poList &&
                  poList.map((item: IPurchaseOrder) => (
                    <div key={item.id} style={{ width: "100%" }}>
                      <ListItem
                        button
                        onClick={() => {
                          // console.log("selected PO", { item });
                          // setSelectedPO(item);
                          // changeStep("third");
                          // Ensure correct number conversion for calculations
                          const updatedItem = {
                            ...item,
                            details: item.details.map((element) => {
                              const taxPercent = Number(element?.tax_rate) ?? 0; // Convert tax_rate to number
                              const base = Number(element.price);                // Convert price to number
                              const quantity = Number(element.quantity);          // Convert quantity to number
                              const discount = Number(element.discount);          // Convert discount to number

                              // Ensure proper calculations and rounding
                              element.total = (base * quantity - discount).toFixed(2);
                              element.tax = (base * taxPercent * quantity).toFixed(2);

                              return element;
                            }),
                          };

                          // Set the selectedPO after recalculating totals
                          setSelectedPO(updatedItem);
                          changeStep("third");
                          
                        }}
                        style={{ width: "100%" }}
                      >
                        <div style={{ width: "100%" }}>OC - {item.id}</div>
                        <div style={{ width: "300px" }}>
                          {item.is_credit === "1" ? (
                            <div style={{ color: "red" }}>
                              <CreditCardIcon />
                              CRÉDITO
                            </div>
                          ) : (
                            <div style={{ color: "green" }}>
                              <PaymentsIcon /> CONTADO
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            // float: "",
                            marginRight: "60px",
                            right: "30px",
                            width: "300px",
                            textAlign: "right",
                          }}
                        >
                          $ {item.total}
                        </div>
                        <div>
                          <Button
                            title="Imprimir Ticket"
                            style={{
                              fontSize: "10px",
                              width: "35px",
                              minWidth: "auto",
                              marginLeft: "5px",
                            }}
                            variant="contained"
                            size="small"
                            color="info"
                            onClick={(e) => {
                              e.stopPropagation();
                              CommonService.printTicketPO(item.id);
                            }}
                          >
                            <PrintIcon />
                          </Button>
                        </div>
                        {/* <ListItemText primary={`PO - ${item.id} `} /> */}
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                {poList?.length === 0 && (
                  <Typography>
                    No hay ordenes de compra para este proveedor
                  </Typography>
                )}
              </List>
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                changeStep("first");
                setPOList([]);
              }}
            >
              Atras
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: "20px" }}
              onClick={() => execute()}
            >
              Siguiente
            </Button>
          </div>
        </div>
      )}
      {step === "third" && (
        <div style={{ textAlign: "center" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    OC - {selectedPO?.id}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Proveedor - {selectedPO?.supplier.nombre}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    RFC - {selectedPO?.supplier.rfc}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <TextField
                      id="standard-basic"
                      label="UUID"
                      fullWidth
                      variant="standard"
                      value={uuid}
                      autoFocus
                      onChange={(e) => setUUID(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <TextField
                      id="standard-basic"
                      label="Serie"
                      variant="standard"
                      value={serie}
                      fullWidth
                      onChange={(e) => setSerie(e.target.value)}
                    />
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    <TextField
                      id="standard-basic"
                      label="Folio"
                      variant="standard"
                      value={folio}
                      fullWidth
                      onChange={(e) => setFolio(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    Detalles
                  </TableCell>
                  <TableCell align="right" colSpan={2}>
                    Precios
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Conceptos</TableCell>
                  <TableCell align="right">Cantidad</TableCell>
                  <TableCell align="right">Precio</TableCell>
                  <TableCell align="right">% IVA</TableCell>
                  <TableCell align="right">IVA</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedPO?.details &&
                  selectedPO.details.map((item: IDetails) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        {item.product.name}
                        {invoiceDetails.length > 0 && (
                          <Select
                            label="Conceptos XML"
                            value={item.xmlValue}
                            style={{ width: "200px" }}
                            onChange={(e) => {
                              console.log(e.target.value);
                              const changeItem = selectedPO;
                              const newDetails: IDetails[] =
                                changeItem.details.map((el) => {
                                  if (el.id === item.id) {
                                    el.xmlValue = `${e.target.value}`;
                                    console.log({ el, value: el.xmlValue });
                                    const foundItem = invoiceDetails.find(
                                      (concept) =>
                                        e.target.value ===
                                        `${concept?.NoIdentificacion?.replace(
                                          /\s/g,
                                          "-"
                                        )}-${concept.Descripcion.replace(
                                          /\s/g,
                                          "-"
                                        )}`
                                    );
                                    if (foundItem) {
                                      el.price = Number(
                                        foundItem.ValorUnitario
                                      ).toFixed(2);
                                      el.tax_percent = foundItem.Impuestos
                                        .Traslados.Traslado[0].TasaOCuota
                                        ? Number(
                                            foundItem.Impuestos.Traslados
                                              .Traslado[0].TasaOCuota
                                          )
                                        : Number(0);
                                      el.quantity = Number(
                                        foundItem.Cantidad
                                      ).toFixed(2);
                                    }
                                  }
                                  return el;
                                });
                              setSelectedPO({
                                ...changeItem,
                                details: newDetails,
                              });
                              updateObject();
                            }}
                          >
                            {invoiceDetails
                            .map((item, index) => (
                              <MenuItem
                                value={`${item?.NoIdentificacion?.replace(
                                  /\s/g,
                                  "-"
                                ) ?? ''}-${item.Descripcion.replace(/\s/g, "-")}`}
                                key={index}
                              >
                                {item?.NoIdentificacion ?? ''}-{item.Descripcion}{" "}
                                {`${item?.NoIdentificacion?.replace(
                                  /\s/g,
                                  "-"
                                ) ?? ''}-${item.Descripcion.replace(/\s/g, "-")}`}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          id="quantity"
                          type="number"
                          style={{ width: "100px" }}
                          variant="standard"
                          value={item.quantity}
                          onChange={(e) => {
                            const changeItem = selectedPO;
                            const newDetails = changeItem.details.map((el) => {
                              if (el.id === item.id) {
                                el.quantity = e.target.value;
                              }
                              return el;
                            });
                            setSelectedPO({
                              ...changeItem,
                              details: newDetails,
                            });
                            updateObject();
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          id="price"
                          type="number"
                          style={{ width: "100px" }}
                          variant="standard"
                          value={item.price}
                          onChange={(e) => {
                            const changeItem = selectedPO;
                            const newDetails = changeItem.details.map((el) => {
                              if (el.id === item.id) {
                                el.price = e.target.value;
                              }
                              return el;
                            });
                            setSelectedPO({
                              ...changeItem,
                              details: newDetails,
                            });
                            updateObject();
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={Number(item?.tax_rate)}
                          style={{ width: "80px" }}
                          label="IVA"
                          onChange={(e) => {
                            const changeItem = selectedPO;
                            const newDetails = changeItem.details.map((el) => {
                              if (el.id === item.id) {
                                el.tax_rate = `${e.target.value}`;
                              }
                              return el;
                            });
                            setSelectedPO({
                              ...changeItem,
                              details: newDetails,
                            });
                            updateObject();
                          }}
                        >
                          <MenuItem value={0}>0</MenuItem>
                          <MenuItem value={0.08}>8</MenuItem>
                          <MenuItem value={0.16}>16</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell align="right">
                        <span style={{ width: "150px" }}>{item.tax}</span>
                      </TableCell>
                      <TableCell align="right" style={{ width: "150px" }}>
                        {item.total}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={4}>Subtotal</TableCell>
                  <TableCell align="right">
                    {ccyFormat(Number(selectedPO?.total))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>Tax</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">
                    {ccyFormat(Number(selectedPO?.tax))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>Total</TableCell>
                  <TableCell align="right">
                    {ccyFormat(Number(selectedPO?.grand_total))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={6}>
                    <TextField
                      id="standard-multiline-static"
                      label="Observaciones"
                      multiline
                      rows={4}
                      style={{ width: "100%" }}
                      variant="outlined"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            size="large"
            onClick={() => changeStep("second")}
          >
            Atras
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ marginLeft: "20px" }}
            onClick={() => execute()}
          >
            Finalizar Recepción
          </Button>
        </div>
      )}
      {step === "createPO" && (
        <CreatePO
          supplierId={supplier?.id}
          handleCancel={() => {
            changeStep("second");
          }}
        />
      )}
    </>
  );
};
