/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "../auth/useLocalStorage";
import { Alert, Snackbar } from "@mui/material";
import { CommonService } from "../services/CommonService";

export default function Login() {
  const [user, setUser] = useLocalStorage("user", null);
  const [transfer] = useLocalStorage("transfer", null);
  const [redirect, setRedirect] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const form = useRef<HTMLFormElement>();

  const [open, setOpen] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleSubmit = async (event?: any) => {
    event?.preventDefault();
    setErrorMessage("");
    const data = new FormData(form?.current);

    let usuario = (data.get("user") as string) ?? "";
    let password = (data.get("password") as string) ?? "";
    if (transfer) {
      usuario = transfer.user.username;
      password = transfer.user.password;
    }
    const loginInformation = await CommonService.login({ usuario, password });
    if (loginInformation.success) {
      setOpen(false);
      setUser({
        token: loginInformation.message,
        id: loginInformation.object?.id,
        warehouse_id: loginInformation.object?.warehouse_id,
        user: loginInformation.object,
      });
      setRedirect(true);
      return;
    }
    setErrorMessage(loginInformation.message);
    setOpen(true);
  };
  useEffect(() => {
    if (user?.token) {
      setRedirect(true);
    }
  }, [user]);

  useEffect(() => {
    if (transfer) {
      const data = new FormData(form?.current);
      data.set("user", transfer.user.username);
      data.set("password", transfer.user.password);
      handleSubmit();
    }
  }, []);

  if (redirect) {
    window.location.replace(process.env.REACT_APP_PREFIX ?? "");
  }
  return (
    <Container component="main" maxWidth="xs">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          className="img-fluid"
          src={`${process.env.PUBLIC_URL}/img/logo1.png`}
          alt="logo"
          style={{ width: "200px" }}
        />

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
          ref={form}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="user"
            label="Usuario"
            name="user"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Iniciar Sesión
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
