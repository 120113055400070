import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  ICustomer,
  IPayment,
  IPrePurchaseOrder,
  IProduct,
  IPurchaseOrder,
  ISale,
} from "../common/interfaces";

interface PosState {
  order: ISale;
  customer: ICustomer;
  products: IProduct[];
  payments: IPayment[];
  authorizations: any[];
  listProducts: IProduct[];
  listCustomers: ICustomer[];
  exchangeRate: number;
  purchaseOrder: {
    [key:string]: IPrePurchaseOrder
  };
}

interface PosActions {
  setOrder: (by: ISale) => void;
  setProducts: (by: IProduct[]) => void;
  addProduct: (by: IProduct) => void;
  setListProducts: (by: IProduct[]) => void;
  setCustomer: (by: ICustomer) => void;
  setAuthorizations: (by: any) => void;
  reset: () => void;
  clearOrder: () => void;
  setListCustomers: (by: ICustomer[]) => void;
  setExchangeRate: (by: number) => void;
  setPurchaseOrder: (supplierId: string, by: IPrePurchaseOrder) => void;
  clearPurchaseOrder: (supplierId: string) => void;
}

const initialState: PosState = {
  order: {
    id: 0,
    cliente_id: 0,
    usuario_id: 0,
    fecha: "",
    totalUSD: "",
    descuento: "",
    total: "",
    subtotal: "",
    iva: "",
    ieps: "",
    metodoPago: "",
    formaPago: "",
    usoCFDI: "",
    notas: "",
    notes_order: "",
    created_at: "",
    updated_at: "",
    status: "",
    sale_type: "",
    origin_by: "",
    cliente: {
      id: 1,
      nombre: "Público en General",
      rfc: "XAXX010101000",
      direccion: "Sin dirección",
      codigoPostal: "88710",
      regimenFiscal: "616",
      usoCFDI: "S01",
      email: "",
      updated_at: "",
    },
    facturas: [],
    detalles: [],
  },
  customer: {
    id: 1,
    nombre: "Público en General",
    rfc: "XAXX010101000",
    direccion: "Sin dirección",
    codigoPostal: "88710",
    regimenFiscal: "616",
    usoCFDI: "S01",
    email: "",
    updated_at: "",
  },
  products: [],
  payments: [],
  authorizations: [],
  listProducts: [],
  listCustomers: [],
  exchangeRate: 0,
  purchaseOrder: {},
};

export const usePosStore = create<PosState & PosActions>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setOrder: (by) => set({ order: by }),
        setProducts: (by) => {
          set({ products: by });
          let subTotal = 0;
          let iva = 0;
          let ieps = 0;
          let discount = 0;
          let total = 0;
          console.log({ total, subTotal, iva, ieps, discount })

          by.forEach((product) => {
            const linePrice = Number((product.price_1 * Number(product.quantity)).toFixed(2));
            subTotal += linePrice;
            iva += Number(((product.iva?.porcentaje ?? 0) * linePrice).toFixed(2));
            ieps += Number(((product.ieps?.porcentaje ?? 0) * linePrice).toFixed(2));
            discount += product.discount ?? 0;
          });
          total = subTotal + iva + ieps - discount;
          console.log({ total, subTotal, iva, ieps, discount })
          const totalUSD = Number(total / get().exchangeRate).toFixed(2);
          set({
            order: {
              ...get().order,
              total: total.toFixed(2),
              subtotal: subTotal.toFixed(2),
              iva: iva.toFixed(2),
              ieps: ieps.toFixed(2),
              descuento: discount.toFixed(2),
              totalUSD: totalUSD,
            },
          });
        },
        addProduct: (by) => set({ products: [by] }),
        setListProducts: (by) => set({ listProducts: by }),
        setCustomer: (by) => set({ customer: by }),
        setAuthorizations: (by) => set({ authorizations: by }),
        reset: () => set(initialState),
        clearOrder: () =>
          set({
            order: initialState.order,
            customer: initialState.customer,
            products: initialState.products,
            payments: initialState.payments,
          }),
        clearPurchaseOrder: (supplierId: string) => {
          const curr = get().purchaseOrder;
          delete curr[supplierId];
          set({ purchaseOrder: curr });
        },
        setListCustomers: (by) => set({ listCustomers: by }),
        setExchangeRate: (by) => set({ exchangeRate: by }),
        setPurchaseOrder: (supplierId: string, by: IPrePurchaseOrder) => {
          const po = get().purchaseOrder
          set({ purchaseOrder: { ...po, [supplierId]: by } })
        },
      }),
      {
        name: "pos-storage",
      }
    )
  )
);
