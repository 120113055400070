import { Typography, Button, LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CommonService } from "../../services/CommonService";
import { usePusher } from "../../context/pusherContext";
import { v4 } from "uuid";
import { usePosStore } from "../../context/posStore";
import { useLocalStorage } from "../../auth/useLocalStorage";
import { useModalStore } from "../../context/modalStore";

export const CancelItem: React.FC = () => {
  const pusher = usePusher();
  const products = usePosStore((state) => state.products);
  const setProducts = usePosStore((state) => state.setProducts)
  const modalManager = useModalStore()
  const [user,] = useLocalStorage('user', null)
  const [idAuth, setIdAuth] = useState(0);
  const item = modalManager.params?.item;
  const cancelRequest = () => {
    if(!item) modalManager.closeModal()
    
    const channel = pusher.subscribe("general-channel");
    const idCancel = v4();
    CommonService.requestAuthorize({
      message: `Se solicita Cancelar el item: Producto ${item.name}`,
      action: {
        type: "cancel-item",
        item: {...item, image: null},
        id: idCancel,
        content: `Se solicita Cancelar el item: <br/> Producto ${item.name} - ${item.barcode} <br>$ ${item.price_1}`
      }, // => $req->action,
      status: 'pending', // => 'pending', // Set the initial status
      from: user.id, // => $req->from,
      to: 1, // => $req->to,
    }).then((res) => {
      if(res.success){
        setIdAuth(res.object.id)
        channel.bind("push-notification", (event: { object: { to?: any; status: string; id: string; }; }) => {
          if (event.object?.to === user.id && event.object.status === 'authorized' && event.object.id === idCancel) {
            if (item.id) {
              const index = products.findIndex(
                (item) => item.id === item.id
              );
              const aux = products;
              aux.splice(index, 1);
              setProducts([...aux]);
            }
          }
          if (event.object?.to === user.id && event.object.status === 'declined' && event.object.id === idCancel) {
            CommonService.toast("Cancelacion No Autorizada", "error")
          }
          modalManager.closeModal()
          channel.unbind("push-notification");
          channel.unsubscribe();
       
        });
      }
    })
  };
  const handleCancel = () => {
    if (idAuth > 0) {
      CommonService.cancelRequestAuthorize({
        message: ``, // => $req->message,
        id: idAuth,
        status: "pending",
        from: user.id,
        to: 1,
      }).then(() => console.log("Cancelando AUTH"));
      setIdAuth(0);
    }
    modalManager.closeModal()
  };

  useEffect(() => {
    cancelRequest()
  }, [])
  return (
    <>
      <Typography style={{ marginBottom: "20px" }}>
        Se envió solicitud eliminar producto de Orden
      </Typography>
      <LinearProgress />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Button
          onClick={handleCancel}
          variant="contained"
          color="error"
          style={{ marginRight: "20px" }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
