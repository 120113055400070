import { Button, Grid } from "@mui/material";
import React, { Component, useEffect } from "react";
import PrintIcon from "@mui/icons-material/Print";
import CropFreeIcon from "@mui/icons-material/CropFree";
import PaymentsIcon from "@mui/icons-material/Payments";
import InventoryIcon from "@mui/icons-material/Inventory";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { VerifyPrice } from "./VerifyPrice";
import { Movements } from "./Movements";
import { SalesReceipt } from "./salesReceipt";
import { SaleService } from "../services/SaleService";
import { IProduct, ISale } from "../common/interfaces";
import { OrderReceipt } from "./orderReceipt";
import { PayOrder } from "./payOrder";
import { CloseBox } from "./closeBox";
import { Tablajeria } from "./tablajeria";
import { Productions } from "./productions";
import TransferInventory from "./transferInventory";
import { CommonService } from "../services/CommonService";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css"
import withReactContent from "sweetalert2-react-content";
import { Withdraw } from './withdraw'
import { PurchaseOrders } from "./purchaseOrders";
import { Expenses } from "./expenses";
import { PaySupplier } from "./modal/paySupplier";
const MySwal = withReactContent(Swal);


interface UtilProps {
  defaultOpen?: string;
  setDefault?: (val: string) => void;
  closeModal: () => void;
  setModalTitle?: (val: string) => void;
}

export const UtilButtons = (props: UtilProps) => {
  const { defaultOpen, setDefault, closeModal, setModalTitle } = props;
  const [displayMenu, setDisplayMenu] = React.useState(true);
  const [currComponent, setCurrComponent] = React.useState<React.FC>();
  const handleOption = (option: JSX.Element, title?:string) => {
    setDisplayMenu(false);
    title && setModalTitle && setModalTitle(title)
    setCurrComponent(() => {
      return option;
    });
  };

  useEffect(() => {
    const options = [...leftButtons, ...rigthButtons];
    if (defaultOpen) {
      const i = options.findIndex((item) => item.id === defaultOpen);
      if (i !== -1) {
        console.log(options[i]);
        options[i].onClick();
      }
    }
  }, []);
  const handleCancel = () => {
    closeModal()
    // setDisplayMenu(true);
    // setModalTitle && setModalTitle("Utilidades")
    // if (setDefault) setDefault("");
  };

  const reprintOrder = (id: number) => {
    SaleService.getSale(id).then((res) => {
      setCurrComponent(() => {
        return (
          <OrderReceipt
            saleInformation={res.object as ISale}
            ticketInformation={{
              customer: res.object?.cliente,
              payment:
                res.object &&
                res.object.payments &&
                res.object.payments.length > 0
                  ? res.object.payments[0].payment_method
                  : "other",
              sale: res.object,
              products: res.object?.detalles.map(
                (item) =>
                  ({
                    ...item.producto,
                    quantity: parseFloat(item.cantidad),
                    name: item.producto.name,
                    price_1: item.producto.price_1,
                  } as IProduct)
              ),
              cashReceived:
                res.object &&
                res.object.payments &&
                res.object.payments.length > 0
                  ? res.object.payments[0].cash_received
                  : 0,
            }}
          />
        );
      });
    });
  };
  const reprintSale = (id: number) => {
    SaleService.getSale(id).then((res) => {
      setCurrComponent(() => {
        return (
          <SalesReceipt
            saleInformation={res.object as ISale}
            ticketInformation={{
              customer: res.object?.cliente,
              payment:
                res.object &&
                res.object.payments &&
                res.object.payments.length > 0
                  ? res.object.payments[0].payment_method
                  : "other",
              sale: res.object,
              products: res.object?.detalles.map(
                (item) =>
                  ({
                    ...item.producto,
                    quantity: parseFloat(item.cantidad),
                    name: item.producto.name,
                    price_1: item.producto.price_1,
                  } as IProduct)
              ),
              cashReceived:
                res.object &&
                res.object.payments &&
                res.object.payments.length > 0
                  ? parseFloat(
                      res.object.payments[0].cash_received as unknown as string
                    )
                  : 0,
            }}
          />
        );
      });
    });
  };
  const reprintRPC = (id: number) => {
    CommonService.printTicketPOR(id).then(e => console.log('Imprimiendo Ticket Recepción'))
  }

  

  const closeOrder = (id: number) => {
    setCurrComponent(() => {
      return (
        <PayOrder
          handleCancel={() => setDisplayMenu(true)}
          saleId={id}
          closeModal={closeModal}
          openTicket={(id: number) => {
            CommonService.printTicketSale(id).then(()=>{console.log('Imprimiendo Ticket')})
            // setOpenModal(false)
            closeModal && closeModal()
            MySwal.fire({
              icon: 'success',
              // title: 'Your work has been saved',
              showConfirmButton: false,
              timer: 1500,
              

            })
            // reprintSale(id);
          }}
        />
      );
    });
  };

  const leftButtons = [
    // {
    //   title: "Abrir Cajon",
    // id: "",
    //   onClick: () => handleOption(''),
    //   icon: <PrintIcon />,
    // },
    {
      title: "Verificador de Precios",
      id: "verify",
      onClick: () => handleOption(<VerifyPrice />, "Verificador"),
      icon: <CropFreeIcon />,
    },
    {
      title: "Movimientos",
      id: "movements",
      onClick: () =>
        handleOption(
          <Movements
            reprintOrder={reprintOrder}
            reprintSale={reprintSale}
            closeOrder={closeOrder}
            reprintRPC={reprintRPC}
          />
        ),
      icon: <SyncAltIcon />,
    },
    {
      title: "Reimpresiones",
      id: "reprint",
      onClick: () =>
        handleOption(
          <Movements
            reprintOrder={reprintOrder}
            reprintSale={reprintSale}
            closeOrder={closeOrder}
            reprintRPC={reprintRPC}
          />
        ),
      icon: <PrintIcon />,
    },

    // {
    //   title: "Etiquetas de precios",
    // id: "",
    //   onClick: () => handleOption(<></>),
    //   icon: <PrintIcon />,
    // },

    {
      title: "Corte de Caja",
      id: "closeBox",
      onClick: () => handleOption(<CloseBox handleCancel={handleCancel} />, "Corte"),
      icon: <ProductionQuantityLimitsIcon />,
    },
    {
      title: "Pedidos Clientes",
      id: "orders",
      onClick: () =>
        handleOption(
          <Movements
            onlyOrders
            reprintOrder={reprintOrder}
            reprintSale={reprintSale}
            closeOrder={closeOrder}
            reprintRPC={reprintRPC}
          />
        ),
      icon: <DeliveryDiningIcon />,
    },

    {
      title: "Producciones",
      id: "productions",
      onClick: () => handleOption(<Productions handleCancel={handleCancel} />, "Producciones"),
      icon: <InventoryIcon />,
    },
    {
      title: "Retiro de Efectivo",
      id: "transfer",
      onClick: () =>
        handleOption(<Withdraw handleCancel={handleCancel} />, "Retiro de efectivo"),
      icon: <InventoryIcon />,
    },
  ];
  const rigthButtons = [
    // {
    //   title: "Devoluciones",
    // id: "",
    //   onClick: () => handleOption(<></>),
    //   icon: <KeyboardReturnIcon />,
    // },

    {
      title: "Tablajeria",
      id: "boardBox",
      onClick: () => handleOption(<Tablajeria handleCancel={handleCancel} />, "Tablajeria"),
      icon: <InventoryIcon />,
    },

    // {
    //   title: "Facturación",
    // id: "",
    //   onClick: () => handleOption(<></>),
    //   icon: <PrintIcon />,
    // },
    {
      title: "Traspaso entre almacenes",
      id: "transfer",
      onClick: () =>
        handleOption(<TransferInventory handleCancel={handleCancel} />, "Transferencia de Inventario"),
      icon: <InventoryIcon />,
    },
    {
      title: "Pago a Proveedores",
      id: "pay",
      onClick: () => handleOption(<PaySupplier handleCancel={handleCancel} />, "Pago a Proveedores"),
      icon: <PaymentsIcon />,
    },
    {
      title: "Gastos",
      id: "bills",
      onClick: () => handleOption(<Expenses handleCancel={handleCancel} />, "Gastos"),
      icon: <PaymentsIcon />,
    },
    {
      title: "Compras",
      id: "purchase",
      onClick: () => handleOption(<PurchaseOrders handleCancel={handleCancel} />, "Compras"),
      icon: <PaymentsIcon />,
    },
    {
      title: "Abrir Cajon",
      id: "",
      onClick: () => {
        CommonService.openCashDrawer().then((res) => {
          console.log('ABRIENDO CAJON');
        })
      },
      icon: <PaymentsIcon />,
    },
    // {
    //   title: "Suspenciones",
    // id: "",
    //   onClick: () => handleOption(<></>),
    //   icon: <PrintIcon />,
    // },
    // {
    //   title: "Valores en Caja",
    // id: "",
    //   onClick: () => handleOption(<></>),
    //   icon: <PrintIcon />,
    // },
    // {
    //   title: "Cerrar Sesión",
    // id: "",
    //   onClick: () => handleOption(<></>),
    //   icon: <LogoutIcon />,
    // },
  ];
  return (
    <>
      {displayMenu ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {leftButtons.map((button, index) => (
              <Button
                fullWidth
                style={{ marginTop: "10px", marginBottom: "10px" }}
                variant="contained"
                key={index}
                onClick={button.onClick}
                startIcon={button.icon}
              >
                {button.title}
              </Button>
            ))}
          </Grid>
          <Grid item xs={6}>
            {rigthButtons.map((button, index) => (
              <Button
                fullWidth
                style={{ marginTop: "10px", marginBottom: "10px" }}
                variant="contained"
                key={index}
                onClick={button.onClick}
                startIcon={button.icon}
              >
                {button.title}
              </Button>
            ))}
          </Grid>
        </Grid>
      ) : (
        currComponent
      )}
    </>
  );
};

export const reprintBill = (id: number) => {
  console.log("Reimpresion de ticket de Gasto ID: "+id);
  CommonService.generatePrintCommand(id, 'bill').then(e => console.log(e));
}