import { ICashBox, ICashBoxDetail, IResponse, IWarehouse, TChashBoxDetails} from "../common/interfaces";

const baseApi = process.env.REACT_APP_API_URL;
const baseRoute = 'cash-box'
const baseServiceRoute = `${baseApi}${baseRoute}`;
export const CashBoxService = {
  openBox: async (params: Partial<ICashBox>): Promise<IResponse<ICashBox>> => {
    const response = await fetch(`${baseServiceRoute}/open`, {
      method: "POST",
      body: JSON.stringify(params),
    });
    return await response.json();
  },
  closeBox: async (params: Partial<ICashBox>): Promise<IResponse<ICashBox>> => {
    const response = await fetch(`${baseServiceRoute}/close`, {
      method: "POST",
      body: JSON.stringify(params),
    });
    return await response.json();
  },
  getMovements: async (cashBoxId: number, onlyOrder = 0): Promise<IResponse<Array<TChashBoxDetails>>> => {
    if(onlyOrder != 0){
      const response = await fetch(`${baseServiceRoute}/${cashBoxId}/movements?order=1`);
      return await response.json();
    }
    const response = await fetch(`${baseServiceRoute}/${cashBoxId}/movements`);
    return await response.json();
  
  },
  createMovement: async (params: Partial<any>): Promise<IResponse<any>> => {
    const response = await fetch(`${baseServiceRoute}/movement`, {
      method: "POST",
      body: JSON.stringify(params),
    });
    return await response.json();
  
  }
};
