import { Button, IconButton, Typography } from "@mui/material";
import * as React from "react";
import ReactToPrint from "react-to-print";
import { getQRCode } from "../services/QrCodeService";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  ICustomer,
  IProduct,
  ISale,
  TPaymentMethod,
} from "../common/interfaces";
import { sha1 } from "crypto-hash";
import moment from "moment";
import { CommonService } from "../services/CommonService";

interface ComponentToPrintProps {
  qrValue: string;
  saleInformation: Partial<ISale>;
  ticketInformation: {
    customer?: ICustomer;
    payment?: TPaymentMethod;
    sale?: ISale;
    products?: IProduct[];
    cashReceived?: number;
    authCode?: string;
  };
}
class ComponentToPrint extends React.Component<ComponentToPrintProps> {

  render() {
    const { qrValue, saleInformation, ticketInformation } = this.props;

    return (
      <div className="component-to-print">
        <Typography style={{ fontSize: "30px" }}>
          ORDEN {ticketInformation.sale?.id}
        </Typography>
        <Typography style={{ fontSize: "15PX", overflow: "auto" }}>
          <span className="pull-left">FOLIO #{ticketInformation.sale?.id}</span>
          <span className="pull-right">CAJA#1</span>
        </Typography>
        <Typography
          style={{ fontSize: "13px", overflow: "auto", textAlign: "left" }}
        >
          CLIENTE: {ticketInformation.customer?.nombre}
          <br />
          DOMICILIO: {ticketInformation.customer?.direccion}
        </Typography>
        <table
          className="table table-bordered"
          style={{ width: "100%", fontSize: "13px" }}
        >
          <thead>
            <tr>
              <th style={{ width: "20%", textAlign: "left" }}>CANT</th>
              <th style={{ width: "60%", textAlign: "center" }}>PRODUCTO</th>
              <th style={{ width: "20%", textAlign: "right" }}>IMPORTE</th>
            </tr>
          </thead>
          <tbody>
            {ticketInformation.products?.map((val, i) => (
              <tr key={"row" + i}>
                <td className="text-right">
                  {val.quantity ? Number(val.quantity).toFixed(2) : 0}
                </td>
                <td className="text-left">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{val.name.substring(0, 20)}
                  {ticketInformation.sale?.detalles.map((value) => (
                    <>
                    <br />  {value.options }<br />
                    </>
                  ))}
                  {val.assembled_products ? val.assembled_products.map((product: IProduct) => {
                            return (<div key={product.id}> - {product.name.substring(0,20)} <br /></div>)
                          }) : ''}
                </td>
                <td className="text-right">
                  {(val.price_1 * (val.quantity ? Number(val.quantity) : 0)).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <table
          style={{
            width: "200px",
            fontSize: "12px",
            marginTop: "20px",
            marginLeft: "180px",
          }}
        >
          <tbody>
            <tr>
              <td>Total</td>
              <td className="text-right">
                ${ticketInformation.sale?.total} MXN
              </td>
            </tr>
          </tbody>
        </table>
        <hr />

        <hr />
        <Typography style={{ fontSize: "10px" }}>
          Fecha: {moment().format("DD/MM/YYYY")} HORA:{" "}
          {moment().format("HH:mm")}
        </Typography>
        <Typography style={{ fontSize: "10px" }}>
          LE ATENDIO: CAJERA 1
        </Typography>
      </div>
    );
  }
}

interface orderReceiptProps {
  trigger?: () => void;
  saleInformation: Partial<ISale>;
  ticketInformation: {
    customer?: ICustomer;
    payment?: TPaymentMethod;
    sale?: ISale;
    products?: IProduct[];
    cashReceived?: number;
  };
}

export const OrderReceipt = (props: orderReceiptProps) => {
  const componentRef = React.useRef(null);
  const { saleInformation, ticketInformation } = props;
  const [saleHash, setSaleHash] = React.useState<string>("");
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div style={{ display: "none", justifyContent: "right" }}>
        <Button variant="contained">
          <LocalPrintshopIcon />
        </Button>
      </div>
    );
  }, []);
  const handleOnClick = () =>{
    CommonService.printTicketOrder(ticketInformation.sale?.id ?? 0).then(() => {
      console.log('Reimprimiendo Ticket')
    })
  }
  React.useEffect(() => {
    (async () => {
      setSaleHash(await sha1(saleInformation.id?.toString() || ""));
    })();
  }, []);
  return (
    <div
      style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}
    >
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        // onAfterPrint={handleAfterPrint}
        // onBeforeGetContent={handleOnBeforeGetContent}
        // onBeforePrint={handleBeforePrint}
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />
       <div style={{ display: "flex", justifyContent: "right" }}>
          <Button variant="contained" onClick={() => handleOnClick()}>
            <LocalPrintshopIcon />
          </Button>
        </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ComponentToPrint
          qrValue={`https://facturacion.labiferia.com.mx/?folio=${saleInformation.id}&total=${saleInformation.total}`}
          saleInformation={saleInformation}
          ticketInformation={ticketInformation}
          ref={componentRef}
        />
      </div>
    </div>
  );
};
