import {
  Grid,
  List,
  ListItem,
  Button,
  Divider,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ICashBoxDetailWPO, IPurchaseOrder } from "../../common/interfaces";
import { CommonService } from "../../services/CommonService";
import PaymentsIcon from "@mui/icons-material/Payments";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useLocalStorage } from "../../auth/useLocalStorage";

interface PaySupplierProps {
  handleCancel: () => void;
}

export const PaySupplier: React.FC<PaySupplierProps> = (props) => {
  const [user] = useLocalStorage("user", null);
  const [step, setStep] = useState("first");
  const [poList, setPoList] = useState<ICashBoxDetailWPO[]>([]);
  const [selectedPO, setSelectedPO] = useState<IPurchaseOrder | undefined>(
    undefined
  );
  useEffect(() => {
    CommonService.getPoToPayInPos(user.cbox.id).then(({ success, object }) => {
      if (success) {
        setPoList(object || []);
      }
    });
  }, []);
  const execute = async () => {
    const {success, object: { amount }} = await CommonService.getAmountCash(user.cbox.id);
    if(success && selectedPO){
      console.log({amount, selectedPO: selectedPO?.total})
      // return;
      const purchaseOrderTotal = Number(selectedPO?.total ?? 0)
      if(amount < purchaseOrderTotal){
        CommonService.toast("No hay fondos suficientes para pagar esta orden", "error");
        return
      }
      CommonService.payPurchaseOrder(selectedPO.id).then(({success}) => {
        if(success){
          CommonService.toast("Orden pagada correctamente", "success");
          CommonService.printTicketPoPayment(selectedPO.id)
          props.handleCancel();
        }
      });
    }
    console.log("execute");
  };
  useEffect(() => {
    console.log(selectedPO);
  }, [selectedPO]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        {step === "first" && (
          <div>
            <List style={{ width: "100%" }} component="div">
              {poList &&
                poList.map(
                  ({
                    purchase_order: item,
                  }: {
                    purchase_order: IPurchaseOrder;
                  }) => (
                    <div key={item.id} style={{ width: "100%" }}>
                      <ListItem
                        button
                        onClick={() => {
                          setSelectedPO(item);
                          setStep("second");
                          CommonService.openCashDrawer();
                        }}
                        style={{ width: "100%" }}
                      >
                        <div style={{ width: "100%" }}>OC - {item.id}</div>
                        <div style={{ width: "300px" }}>
                          {item.is_credit === "1" ? (
                            <div style={{ color: "red" }}>
                              <CreditCardIcon />
                              CRÉDITO
                            </div>
                          ) : (
                            <div style={{ color: "green" }}>
                              <PaymentsIcon /> CONTADO
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            // float: "",
                            marginRight: "60px",
                            right: "30px",
                            width: "300px",
                            textAlign: "right",
                          }}
                        >
                          $ {item.total}
                        </div>
                        <div></div>
                        {/* <ListItemText primary={`PO - ${item.id} `} /> */}
                      </ListItem>
                      <Divider />
                    </div>
                  )
                )}
              {poList?.length === 0 && (
                <Typography>
                  No hay ordenes de compra pendientes de Pago
                </Typography>
              )}
            </List>
            <div style={{ marginTop: "30px" }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => props.handleCancel()}
                >
                  Cancelar
                </Button>
              </Stack>
            </div>
          </div>
        )}
        {step === "second" && selectedPO !== undefined && (
          <div>
            <Typography style={{ fontSize: "24px" }}>
              El pago de la Orden de Compra sera en Efectivo, asegurate de tener
              lo suficiente.
            </Typography>
            <Typography style={{ textAlign: "left" }}>
              <span style={{ fontWeight: "bold" }}>Proveedor: </span>{" "}
              {selectedPO?.supplier?.nombre}
            </Typography>
            <Typography style={{ textAlign: "left" }}>
              <span style={{ fontWeight: "bold" }}>Orden de Compra: </span>{" "}
              {selectedPO.id}
            </Typography>
            <Typography style={{ textAlign: "left" }}>
              <span style={{ fontWeight: "bold" }}>Total: </span>{" "}
              {selectedPO.grand_total}
            </Typography>
            <div style={{ marginTop: "30px" }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => props.handleCancel()}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setStep("first")}
                >
                  Atras
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{ marginLeft: "20px" }}
                  onClick={() => execute()}
                >
                  Pagar OC
                </Button>
              </Stack>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
