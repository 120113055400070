import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";

interface LoaderProps {
  display: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ display }) => {
  return (
    <>
      {display && (
        <div className="text-center" style={{ marginTop: "100px" }}>
          <CircularProgress color="primary" variant="indeterminate" />
        </div>
      )}
    </>
  );
};
