import * as React from "react";

import { Typography } from "@mui/material";


export const Clear = () => {
  localStorage.clear()
  window.location.replace(process.env.REACT_APP_PREFIX ?? "");

  return (<Typography>Clear</Typography>)
  
};
