import {
  Typography,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ComponentModal,
  IGifCard,
  IPayment,
  IProduct,
  PaymentsMethods,
  PaymentsMethodsKey,
  PaymentsMethodsLabel,
  TPaymentMethod,
  TRequestSale,
} from "../../common/interfaces";
import { useModalStore } from "../../context/modalStore";
import { CommonService } from "../../services/CommonService";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import { usePosStore } from "../../context/posStore";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocalStorage } from "../../auth/useLocalStorage";
import { SaleService } from "../../services/SaleService";
import { v4 } from "uuid";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const Finish: React.FC = () => {
  let pays: any[] = [];
  const [user] = useLocalStorage("user", null);
  const clearOrder = usePosStore((state) => state.clearOrder);
  const modalManager = useModalStore();
  const exchangeRate = usePosStore((state) => state.exchangeRate);
  const order = modalManager.params?.order;
  const products = modalManager.params?.products;
  const customer = modalManager.params?.customer;
  const type = modalManager.params?.type;
  const [authCode, setAuthCode] = useState("");
  const [amountToPay, setAmountToPay] = useState("0");
  const [amountToPayUsd, setAmountToPayUsd] = useState("0");
  const [newPayment, setNewPayment] = useState<string>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentsMethods>();
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [allowCreateSale, setAllowCreateSale] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [leftover, setLeftover] = useState(0);
  const [leftoverUsd, setLeftoverUsd] = useState(0);
  const [isValidGiftCard, setIsVaLidGiftCard] = useState(false);
  const [gifCardInformation, setGifCardInformation] = useState<IGifCard>();
  useEffect(() => {
    console.log({ amountToPay });
    const totalPayments = payments.reduce((acc, curr) => acc + curr.amount, 0);
    const aux = totalPayments + Number(amountToPay);
    console.log({ aux, totalPayments, amountToPay });
    const totalOrder = Number(order.total);
    const leftover = Number((aux - totalOrder).toFixed(2));
    if (leftover >= 0) setAllowCreateSale(true);
    else setAllowCreateSale(false);
    setLeftover(leftover);
    setLeftoverUsd(leftover / exchangeRate);
  }, [amountToPay]);
  useEffect(() => {
    console.log({ newPayment });
    if (newPayment) {
      const totalPayments = payments.reduce(
        (acc, curr) => acc + curr.amount,
        0
      );
      const totalOrder = Number(order.total);
      const aux = Number((totalOrder - totalPayments).toFixed(2));
      console.log("NEWPAYMENT HOOK", { totalPayments, totalOrder, aux });
      setAmountToPay(aux.toFixed(2));
      setAmountToPayUsd((aux / exchangeRate).toFixed(2));
    }
  }, [newPayment]);
  // useEffect(() => {
  //   const usdToPay = Number(amountToPayUsd);
  //   setAmountToPay((usdToPay * exchangeRate).toFixed(2));
  // }, [amountToPayUsd]);

  const handleEnter = (evt: any) => {
    // if (evt.key === "Enter") {
    //   const aux = payments.reduce((acc, curr) => acc + curr.amount, 0);
    //   const finalizar =
    //     parseFloat((aux + parseFloat(amountToPay)).toFixed(2)) - total >= 0;
    //   console.log({ finalizar });
    //   if (
    //     paymentMethod === "cash" ||
    //     paymentMethod === "usd" ||
    //     paymentMethod === "credit_card" ||
    //     paymentMethod === "debit_card"
    //   ) {
    //     if (finalizar) {
    //       createSale();
    //       return;
    //     }
    //     addPayment();
    //   }
    // }
  };

  const createSale = () => {
    setDisableSend(true);
    if (pays.length === 0) {
      CommonService.toast("No hay pagos", "error");
    }
    const auxOrder = products.some(
      (product: IProduct) => product.options && product.options.length > 0
    );
    console.log({ products });
    const data: TRequestSale = {
      orderProducts: products,
      total: order.total,
      subtotal: order.subtotal,
      iva: order.iva,
      ieps: order.ieps,
      descuento: order.discount,
      metodoPago: pays[0].payment_method,
      shipping: 0,
      notes_order: order.notes_order,
      // schedule,
      cliente: customer,
      sale_type: auxOrder ? "Order" : "Simple",
      payments: pays,
      user_id: user.id,
      warehouse_id: user.warehouse_id,
      cash_received: 0, //parseFloat(amountReceived),
      cashBox: user.cbox,
      notes: "",
      state: undefined,
      location: undefined,
    };

    SaleService.createSalePos(data).then((res) => {
      if (res.success) {
        const saleId = res.object?.id ?? 0;
        const paymentTransfer = pays.find(
          (payment) => payment.payment_method === PaymentsMethods.Transfer
        );
        const paymentCredit = pays.find(
          (payment) => payment.payment_method === PaymentsMethods.Credit
        );
        if (paymentCredit) {
          const idAuth = v4();
          CommonService.requestAuthorize({
            message: `Se solicita Autorizar un pago a Crédito de $ ${paymentCredit.amount.toFixed(
              2
            )}`, // => $req->message,
            action: {
              type: "authorize-payment",
              item: { order_id: saleId },
              id: idAuth,
              content: `Se solicita Autorizar un pago a Crédito de $ ${paymentCredit.amount.toFixed(
                2
              )}`,
            }, // => $req->action,
            status: "pending", // => 'pending', // Set the initial status
            from: user.id, // => $req->from,
            to: 1, // => $req->to,
          }).then(() => {
            CommonService.toast(
              "Se envío solicitud de Autorización, para validar pago a Crédito",
              "success"
            );
            modalManager.closeModal();
          });
        } else if (paymentTransfer) {
          const idAuth = v4();
          CommonService.requestAuthorize({
            message: `Se solicita Autorizar un pago por Transferencia de $ ${paymentTransfer.amount.toFixed(
              2
            )}`, // => $req->message,
            action: {
              type: "authorize-payment",
              item: { order_id: saleId },
              id: idAuth,
              content: `Se solicita Autorizar un pago por Transferencia de $ ${paymentTransfer.amount.toFixed(
                2
              )}`,
            }, // => $req->action,
            status: "pending", // => 'pending', // Set the initial status
            from: user.id, // => $req->from,
            to: 1, // => $req->to,
          }).then(() => {
            CommonService.toast(
              "Se envío solicitud de Autorización, para validar pago por transferencia",
              "success"
            );
            modalManager.closeModal();
          });
          // pusher.
        } else {
          CommonService.printTicketSale(saleId)
            .then(() => {
              console.log("printing");
              if (auxOrder) {
                CommonService.printTicketOrder(saleId)
                  .then(() => {
                    console.log("printing Comanda");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
          modalManager.closeModal();
          MySwal.fire({
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        clearOrder();
        // setOpenModal(false)
      } else {
        CommonService.toast(res.message, "error");
      }
      setDisableSend(false);
    });
  };

  const validateGifCard = () => {
    CommonService.getGifCardInformation(authCode).then((res) => {
      if (
        res.success &&
        res.object?.status === "Active" &&
        Number(res.object?.amount) > 0
      ) {
        setIsVaLidGiftCard(true);
        if (Number(order.total) > Number(res.object.amount)) {
          setAmountToPay(res.object.amount);
        } else {
          setAmountToPay(Number(order.total).toFixed(2));
        }
        setGifCardInformation(res.object as IGifCard);
        return;
      }
      setGifCardInformation({} as IGifCard);
      setIsVaLidGiftCard(false);
      CommonService.toast(
        "La tarjeta de regalo no es valida o no tiene fondos.",
        "error"
      );
    });
  };

  const addPayment = (generateSale = false) => {
    const amount = Number(amountToPay);
    if (newPayment === PaymentsMethods.GiftCard) {
      if (isValidGiftCard) {
        if (Number(gifCardInformation?.amount ?? 0) < amount) {
          CommonService.toast(
            "La tarjeta de regalo no tiene fondos suficientes para este pago",
            "error"
          );
          return;
        }
      } else {
        CommonService.toast(
          "La tarjeta de regalo no es valida, o no ha sido verificada.",
          "error"
        );
        return;
      }
    }

    if (amount === 0) {
      CommonService.toast("Necesita agregar un monto, para el pago", "error");
      return;
    }
    const payment: IPayment = {
      amount:
        amount > Number(order.total)
          ? Number(order.total)
          : Number(amount.toFixed(2)),
      payment_method: newPayment as TPaymentMethod,
      cash_received: Number(amount.toFixed(2)),
      auth_code: authCode,
      usd: Number(amountToPayUsd),
      exchange_rate: exchangeRate,
      status: newPayment === PaymentsMethods.Transfer ? "pending" : "approved",
    };
    // setCounter( (counter) => { return counter + 1 } )
    setPayments([...payments, payment]);
    // setPayments((payments) => [...payments, payment])
    pays = [...payments, payment];
    setNewPayment(undefined);
    setAmountToPay("0"); //leftover.toFixed(2))
    setAmountToPayUsd("0"); //(leftover / exchangeRate).toFixed(2));
    setAuthCode("");
    setIsVaLidGiftCard(false);
    setGifCardInformation({} as IGifCard);

    if (generateSale) {
      const auxOrder = products.some(
        (product: IProduct) => product.options && product.options.length > 0
      );
      if (auxOrder) {
        modalManager.setComponent(ComponentModal.OrderOptions, {
          order,
          pays,
          customer,
        });
        return;
      }
      console.log({ pays });
      createSale();
    }
  };
  const deletePayment = (id: number) => {
    const aux = payments;
    aux.splice(id, 1);
    pays = [...pays.splice(id, 1)];
    setAmountToPay("0"); //auxTotal.toFixed(2));
    setAmountToPayUsd("0"); // (auxTotal / exchangeRate).toFixed(2));
    setAuthCode("");
    setIsVaLidGiftCard(false);
    setGifCardInformation({} as IGifCard);
    setNewPayment(undefined);
    setPayments([...aux]);
  };
  return (
    <div style={{ textAlign: "center" }}>
      <Typography>Total a pagar:</Typography>
      <Typography fontSize={35}>
        $ {order.total} MXN ({order.totalUSD} USD)
      </Typography>
      <ButtonGroup
        size="large"
        aria-label="large button group"
        style={{ marginTop: "20px" }}
      >
        <Button key="one" onClick={() => setNewPayment(PaymentsMethods.Cash)}>
          {PaymentsMethodsLabel.Cash}
        </Button>
        <Button key="two" onClick={() => setNewPayment(PaymentsMethods.Usd)}>
          {PaymentsMethodsLabel.Usd}
        </Button>
        <Button
          key="three"
          onClick={() => setNewPayment(PaymentsMethods.CreditCard)}
        >
          {PaymentsMethodsLabel.CreditCard}
        </Button>
        <Button
          key="four"
          onClick={() => setNewPayment(PaymentsMethods.DebitCard)}
        >
          {PaymentsMethodsLabel.DebitCard}
        </Button>
        <Button
          key="five"
          onClick={() => setNewPayment(PaymentsMethods.GiftCard)}
        >
          {PaymentsMethodsLabel.GiftCard}
        </Button>
        <Button key="six" onClick={() => setNewPayment(PaymentsMethods.Vales)}>
          {PaymentsMethodsLabel.Vales}
        </Button>
        <Button
          key="seven"
          onClick={() => setNewPayment(PaymentsMethods.Transfer)}
        >
          {PaymentsMethodsLabel.Transfer}
        </Button>
      </ButtonGroup>
      {customer.allow_credit === 1 && customer.current_credit >= Number(order.total) && (
        <ButtonGroup
          size="large"
          aria-label="large button group"
          style={{ marginTop: "20px" }}
        >
          <Button
            key="eight"
            onClick={() => setNewPayment(PaymentsMethods.Credit)}
          >
            {PaymentsMethodsLabel.Credit}
          </Button>
        </ButtonGroup>
      )}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            fontSize: "20px",
          }}
        >
          {newPayment
            ? PaymentsMethodsLabel[
                PaymentsMethodsKey[
                  newPayment as keyof typeof PaymentsMethodsKey
                ] as keyof typeof PaymentsMethodsLabel
              ]
            : ""}
        </Typography>
        {newPayment === PaymentsMethods.Cash && (
          <>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPay}
                onKeyDown={handleEnter}
                onChange={(event) => {
                  setAmountToPay(event.target.value);
                }}
              />
            </FormControl>

            {leftover > 0 && (
              <>
                <Typography style={{ marginTop: "20px" }}>
                  {"Cambio"}
                </Typography>
                <Typography fontSize={35}>
                  ${` ${leftover.toFixed(2)}`}MXN
                </Typography>
              </>
            )}
          </>
        )}
        {(newPayment === PaymentsMethods.CreditCard ||
          newPayment === PaymentsMethods.DebitCard) && (
          <>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPay}
                onChange={(event) => {
                  if (Number(event.target.value) > Number(order.total)) {
                    setAmountToPay(order.total.toFixed(2));
                    return;
                  }
                  setAmountToPay(event.target.value);
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} style={{ width: "230px" }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Código de Autorización
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                label="Código"
                type="text"
                value={authCode}
                onKeyDown={handleEnter}
                onChange={(event) => {
                  setAuthCode(event.target.value);
                }}
              />
            </FormControl>
          </>
        )}
        {newPayment === PaymentsMethods.GiftCard && (
          <>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPay}
                onChange={(event) => {
                  if (Number(event.target.value) > Number(amountToPay)) {
                    setAmountToPay(amountToPay);
                  }
                  setAmountToPay(event.target.value);
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} style={{ width: "230px" }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Código
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                label="Código"
                type="text"
                value={authCode}
                onChange={(event) => {
                  setAuthCode(event.target.value);
                }}
                endAdornment={
                  <IconButton>
                    {isValidGiftCard ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <BlockIcon />
                    )}
                  </IconButton>
                }
              />
            </FormControl>
            <div>
              {gifCardInformation?.id && (
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    backgroundColor: "#333",
                    color: "#fff",
                    padding: "20px",
                  }}
                >
                  <Typography style={{ fontSize: "20px" }}>
                    Monto: ${gifCardInformation?.amount}
                  </Typography>
                  <Typography style={{ fontSize: "20px" }}>
                    Estatus: {gifCardInformation?.status}
                  </Typography>
                </div>
              )}
            </div>
            <Button variant="contained" onClick={validateGifCard}>
              Validar Codigo de Tarjeta de Regalo
            </Button>
          </>
        )}
        {newPayment === PaymentsMethods.Vales && (
          <>
            <Typography>
              Recuerda que pagar con vales no se da cambio.
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPay}
                onChange={(event) => {
                  setAmountToPay(event.target.value);
                }}
              />
            </FormControl>
          </>
        )}
        {newPayment === PaymentsMethods.Usd && (
          <>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPayUsd}
                onKeyDown={handleEnter}
                onChange={(event) => {
                  setAmountToPayUsd(event.target.value);
                  setAmountToPay(
                    (Number(event.target.value) * exchangeRate).toFixed(2)
                  );
                }}
              />
            </FormControl>
            {leftoverUsd > 0 && (
              <>
                <Typography style={{ marginTop: "20px" }}>
                  {"Cambio"}
                </Typography>
                <Typography fontSize={35}>
                  ${` ${leftover.toFixed(2)}`}MXN
                </Typography>
              </>
            )}
          </>
        )}
        {newPayment === PaymentsMethods.Transfer && (
          <Typography
            style={{
              marginTop: "10px",
              marginBottom: "20px",
              fontSize: "20px",
            }}
          >
            {" "}
            $ {amountToPay} MXN
          </Typography>
        )}
        {newPayment === PaymentsMethods.Credit &&
          customer.allow_credit === 1 && (
            <Typography
              style={{
                marginTop: "10px",
                marginBottom: "20px",
                fontSize: "20px",
              }}
            >
              {" "}
              $ {amountToPay} MXN
            </Typography>
          )}
        <div>
          {payments.length > 0 && (
            <div>
              <Typography style={{ marginTop: "20px" }}>Pagos:</Typography>

              <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
                <Table
                  sx={{ minWidth: "80%" }}
                  aria-label="simple table"
                  style={{ backgroundColor: "#333", color: "#fff" }}
                >
                  <TableBody>
                    {payments.map((payment, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            style={{ fontSize: "20px" }}
                          >
                            {
                              PaymentsMethodsLabel[
                                PaymentsMethodsKey[
                                  payment.payment_method as keyof typeof PaymentsMethodsKey
                                ] as keyof typeof PaymentsMethodsLabel
                              ]
                            }
                          </TableCell>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            style={{ fontSize: "20px" }}
                          >
                            {" "}
                            $ {payment.amount}
                          </TableCell>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            style={{ fontSize: "20px" }}
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() => deletePayment(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => modalManager.closeModal()}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ marginLeft: "20px" }}
            onClick={() =>
              modalManager.setComponent(ComponentModal.OrderOptions, {
                order,
                customer
              })
            }
          >
            Crear Pedido
          </Button>
          {leftover < 0 && newPayment && (
            <>
              <Button
                variant="contained"
                size="large"
                style={{ marginLeft: "20px" }}
                onClick={() => addPayment()}
              >
                Agregar Pago
              </Button>
            </>
          )}
          {allowCreateSale && (
            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: "20px" }}
              onClick={() => addPayment(true)}
              disabled={disableSend}
            >
              Terminar Venta
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
